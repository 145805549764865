
import DashboardModule from "@/store/modules/Dashboard";
import { Component, Vue } from "vue-property-decorator";
import Offers from "@/components/Main/overview/offers/Offers.vue";
import AdjustLoanSlider from "@/components/Main/overview/AdjustLoanSlider.vue";
@Component({
  components: {
    AdjustLoanSlider,
    Offers,
  },
})
export default class OfferSection extends Vue {
  get getLoadingLeads() {
    return DashboardModule.getLoadingLeads;
  }
  get getLeads() {
    return DashboardModule.getLeadsForAffordability;
  }
  get getAllLeads() {
    return DashboardModule.getLeads;
  }
  // async refreshLeads() {
  //   const el = this.$refs.slider as AdjustLoanSlider;
  //   console.log("Refresh leads", el);
  //   if (el) {
  //     await el.loadLeads(el.getInstalments);
  //   }
  // }
}
