
import { DashboardLib } from "@/helpers";
import AuthModule from "@/store/modules/Auth";
import { axiosInstance } from "@/plugins/axios";
import AccountLinking from "@/store/modules/AccountLinking";
import { Component, Vue, Watch } from "vue-property-decorator";
import { TokenInfo } from "@/models/user";
import dayjs from "dayjs";

@Component
export default class ResumeSessionJWT extends Vue {
  get getTokenInfo() {
    return AuthModule.getTokenInfo;
  }
  get jwt() {
    return AuthModule.getExpiringJWT;
  }
  set jwt(val: { open: boolean; interval: number }) {
    AuthModule.setExpiringJWT(val);
  }
  // get open() {
  //   return this.jwt.open;
  // }
  // set open(val: boolean) {
  //   this.jwt.open = val;
  // }
  deactivated() {
    clearInterval(this.jwt.interval);
  }
  beforeDestroy() {
    clearInterval(this.jwt.interval);
  }
  logout() {
    clearInterval(this.jwt.interval);
    AuthModule.logout();
    this.$router.push({
      name: "unauthed.landing",
    });
  }
  async reloadDash() {
    try {
      if (AuthModule.getTokenInfo && AuthModule.getTokenInfo.exists == "true") {
        await DashboardLib.getData();
      }
    } catch (err) {
      this.logout();
    } finally {
      this.jwt.open = false;
    }
  }
  @Watch("getTokenInfo", {
    deep: true,
  })
  onTokenChange(val: TokenInfo | null, oldVal: TokenInfo | null) {
    if (val) {
      console.log("Token detected: ", val, dayjs.unix(val.exp));
      // get difference between now and exp. - 3 minutes and open modal
      const triggerTime = dayjs.unix(val.exp).subtract(5, "minutes");
      console.log("Trigger time : ", triggerTime);
      if (dayjs().isBefore(triggerTime)) {
        console.log(
          "Set and open here : ",
          triggerTime,
          triggerTime.diff(dayjs(), "milliseconds")
        );
        clearInterval(this.jwt.interval);
        this.jwt.interval = setTimeout(() => {
          this.jwt.open = true;
          clearInterval(this.jwt.interval);
        }, triggerTime.diff(dayjs(), "milliseconds"));
      } else {
        clearInterval(this.jwt.interval);
      }
    } else {
      clearInterval(this.jwt.interval);
    }
  }
}
