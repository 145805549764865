
import { Component, Vue, Watch } from "vue-property-decorator";
import UserModule from "@/store/modules/User";

@Component
export default class CompleteYourProfile extends Vue {
  currentProgress = 55;
  profile = false;
  updateProfile() {
    console.log("Update profile modal should appear");
    UserModule.setPersonalDetailsOpen(true);
  }
  verifyIdentity() {
    console.log("Verify identity modal should appear");
    UserModule.setVerifyIdentity(true);
  }
  verifyIncome() {
    console.log("Verify income modal should appear");
    UserModule.setVerifyIncome(true);
  }
}
