
import { Component, Vue, Watch } from "vue-property-decorator";

// Components
import Overview from "@/components/Main/Overview.vue";
import Consent from "@/components/AccountLinking/Consent/Consent.vue";
import BankSelection from "@/components/AccountLinking/BankSelection/BankSelection.vue";
import BankLogin from "@/components/AccountLinking/BankLogin/BankLogin.vue";
// 2fa
import OTP from "@/components/AccountLinking/Authenticator/2fa/OTP/OTP.vue";
import Surephrase from "@/components/AccountLinking/Authenticator/2fa/Surephrase/Surephrase.vue";
import MobileAuth from "@/components/AccountLinking/Authenticator/2fa/MobileAuth/MobileAuth.vue";
import ProcessingStatus from "@/components/AccountLinking/Authenticator/ProcessingStatus/ProcessingStatus.vue";
import AccountSelection from "@/components/AccountLinking/Authenticator/AccountSelection/AccountSelection.vue";
import GetStarted from "@/components/AccountLinking/GetStarted/GetStarted.vue";
import Error from "@/components/AccountLinking/Authenticator/Error/Error.vue";
import Timeout from "@/components/AccountLinking/Timeout/Timeout.vue";

import Cancelled from "@/components/Main/Cancelled.vue";
import DashboardModule from "@/store/modules/Dashboard";
import { SocketLib } from "@/helpers";
import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";
import Auth from "@/store/modules/Auth";

@Component({
  components: {
    Overview,
    GetStarted,
    Consent,
    BankSelection,
    BankLogin,
    AccountSelection,
    OTP,
    Surephrase,
    MobileAuth,
    ProcessingStatus,
    // Login,
    Cancelled,
    Error,
    Timeout,
  },
})
export default class Main extends Vue {
  get currentComponent() {
    return store.getters["AccountLinking/getCurrentComponent"];
    // return AccountLinking.getCurrentComponent;
  }
  set currentComponent(val: PAGE | null) {
    store.commit("AccountLinking/setCurrentComponent", val);
    // AccountLinking.setCurrentComponent(val);
  }
  get currentFlow(): SocketFlow {
    if (Auth.getTokenInfo) {
      if (Auth.getTokenInfo.exists == "true") {
        return "Dashboard/AccountLinking";
      }
    }
    return "AccountLinking";
  }
  async activated() {
    // load api for current dashboard stats
    //
    console.warn("Main activated");
    if (Auth.getTokenInfo) {
      if (Auth.getTokenInfo.exists == "true") {
        console.warn("Required to get data!!!");
        this.currentComponent = PAGE.Overview;
      } else {
        if (
          this.currentComponent == PAGE.Overview ||
          this.currentComponent == null
        ) {
          console.warn("Skippppp ", Auth.getTokenInfo);
          this.currentComponent = PAGE.GetStarted;
        }
      }
    }
  }
  get getLastStatusAccountLinking() {
    return store.getters["AccountLinking/getLastStatus"];
    // return AccountLinking.getLastStatus;
  }
  get getLastStatusDashboard() {
    return DashboardModule.getLastStatus;
  }
  created() {
    if (store.hasModule(["AccountLinking", "Socket"]) == false) {
      // store.registerModule("AccountLinking/Socket", SocketModule);
    }
  }
  // @Watch("getLastStatusAccountLinking", { deep: true })
  // statusChanged(val: any) {
  //   console.log("Get last status change AL ", val);
  //   if (val) {
  //     SocketLib.processTopicUpdate(val, this.currentFlow);
  //   }
  // }
  @Watch("getLastStatusAccountLinking", { deep: true })
  @Watch("getLastStatusDashboard", { deep: true })
  async dashStatusChanged(val: any) {
    console.log("Testing this watcher ", val);
    if (val) {
      await SocketLib.processTopicUpdate(val, this.currentFlow);
    }
  }
}
