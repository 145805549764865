import { SocketFlow } from "@/models/nav";
import { axiosOldInstance } from "@/plugins/axios";
import store from "@/store";

const DataProviders = () => {
  const load = async (vuexPath: SocketFlow) => {
    const id = store.getters[`${vuexPath}/getCollectionID`];
    if (id) {
      const res = await axiosOldInstance
        .get(`/collections/${id}/data-providers`)
        .catch((e) => Promise.reject(e));
      // TODO loop and add
      store.commit(`${vuexPath}/setDataProviders`, res.data);
      // DataProvidersModule.setDataProviders(res.data);
      console.log("Data providers:", res.data);
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(
        "No collection ID " +
          store.getters[`${vuexPath}/getCollectionID`] +
          vuexPath
      );
    }
  };
  return {
    load,
  };
};
const dataProvidersLib = DataProviders();
export { dataProvidersLib };
