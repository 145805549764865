
// import { panelInfo } from "@/models/dashboard";
import { cumulative } from "@/models/dashboard";
import DashboardModule from "@/store/modules/Dashboard";
import { Component, Vue } from "vue-property-decorator";
import OverviewPanel from "./OverviewPanel.vue";

@Component({
  components: {
    OverviewPanel,
  },
})
export default class OverviewPanels extends Vue {
  get getEarnings(): cumulative {
    return {
      average: 0,
      delta: 0,
    };
  }
  get getData() {
    if (DashboardModule.getDashboardData == null) return null;
    return DashboardModule.getDashboardData["json-data"].cumulatives;
  }
}
