
import { Component, Vue, Watch } from "vue-property-decorator";
interface ACCOUNT {
  icon: string;
  bankId: string;
  mask: string;
  text: string;
  color: string;
}
interface BANK_GROUP {
  id: string;
  name: string;
  items: ACCOUNT[];
}
@Component
export default class Accounts extends Vue {
  get getSortedAccounts() {
    const groups: {
      id: string;
      name: string;
      items: ACCOUNT[];
    }[] = [];
    this.accounts.forEach((acc) => {
      const temp = {
        id: acc.bankId,
        name: acc.text,
        items: [],
      };
      const index = groups.findIndex((g) => g.id == acc.bankId);
      if (index == -1) {
        groups.push(temp);
      }
    });
    this.accounts.forEach((acc) => {
      const index = groups.findIndex((b) => b.id == acc.bankId);
      if (index == -1) {
        const newGroup: BANK_GROUP = {
          id: acc.bankId,
          name: acc.text,
          items: [acc],
        };
        groups.push(newGroup);
      } else {
        groups[index].items.push(acc);
      }
    });
    return groups;
  }
  accounts: ACCOUNT[] = [
    {
      icon: "mdi-bank",
      bankId: "fnb",
      mask: "****7568",
      text: "Fnb",
      color: "light-blue",
    },
    {
      icon: "mdi-bank",
      mask: "****7568",
      bankId: "absa",
      text: "Absa",
      color: "red",
    },
    {
      icon: "mdi-bank",
      bankId: "standardbank",
      mask: "****7568",
      text: "Standard Bank",
      color: "blue",
    },
    {
      icon: "mdi-bank",
      bankId: "standardbank",
      mask: "****1234",
      text: "Standard Bank",
      color: "blue",
    },
    {
      icon: "mdi-bank",
      mask: "****7568",
      bankId: "capitec",
      text: "Capitec",
      color: "grey",
    },
    {
      icon: "mdi-bank",
      mask: "****9876",
      bankId: "capitec",
      text: "Capitec",
      color: "grey",
    },
    {
      icon: "mdi-bank",
      mask: "****7568",
      bankId: "nedbank",
      text: "Nedbank",
      color: "green",
    },
  ];
}
