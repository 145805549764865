import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

//views
import Authenticated from "@/views/Authenticated.vue";
import Unauthenticated from "@/views/Unauthenticated.vue";

//app comps
import NavDrawer from "@/components/NavDrawer.vue";
import AppBar from "@/components/AppBar.vue";
import Footer from "@/components/Footer.vue";

//auth
import SignIn from "@/views/auth/SignIn.vue";
import SignUp from "@/views/auth/SignUp.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import TCs from "@/views/auth/TermsAndConditions.vue";
import OTP from "@/views/auth/OTP.vue";

import NotFound from "@/views/NotFound.vue";
import Dashboard from "@/views/dashboard/Main.vue";
import ServiceProviders from "@/views/dashboard/ServiceProviders.vue"; // change this
import Accounts from "@/views/dashboard/Accounts.vue"; // change this
import Help from "@/views/dashboard/Help.vue";
import Profile from "@/views/dashboard/Profile.vue";
import LandingUnAuthed from "@/views/auth/Landing.vue";
//-----------------------------------------------//
//vuex
import AuthModule from "@/store/modules/Auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    meta: { requiresAuth: false },
    component: Unauthenticated,
    children: [
      {
        path: "landing",
        name: "unauthed.landing",
        component: LandingUnAuthed,
        // meta: { transition: "slide-up" },
        meta: {
          title: "truID Login",
          metaTags: [
            {
              name: "description",
              content: "Log in to Portal.",
            },
            {
              property: "og:description",
              content: "Log in to Portal.",
            },
          ],
        },
      },
      {
        path: "signin",
        name: "signin",
        component: SignIn,
        // meta: { transition: "slide-up" },
        meta: {
          title: "truID Login",
          metaTags: [
            {
              name: "description",
              content: "Log in to Portal.",
            },
            {
              property: "og:description",
              content: "Log in to Portal.",
            },
          ],
        },
      },
      {
        path: "otp",
        name: "otp",
        component: OTP,
        // meta: { transition: "slide-up" },
        meta: {
          title: "truID OTP",
          metaTags: [
            {
              name: "description",
              content: "Verify OTP",
            },
            {
              property: "og:description",
              content: "Verify OTP",
            },
          ],
        },
      },
      {
        path: "signup",
        name: "signup",
        component: SignUp,
        meta: {
          title: "truID Register",
          metaTags: [
            {
              name: "description",
              content: "Register to Portal.",
            },
            {
              property: "og:description",
              content: "Register to Portal.",
            },
          ],
        },
      },
      {
        path: "forgot-password",
        name: "forgotPassword",
        component: ForgotPassword,
        // meta: { transition: "slide-right" },
        meta: {
          title: "truID Reset Password",
          metaTags: [
            {
              name: "description",
              content: "Reset password for Portal.",
            },
            {
              property: "og:description",
              content: "Reset password for Portal.",
            },
          ],
        },
      },
      {
        path: "terms-and-conditions",
        name: "tcs",
        component: TCs,
        // meta: { transition: "slide-right" },
        meta: {
          title: "truID Terms and Conditions",
          metaTags: [
            {
              name: "description",
              content: "Terms and Conditions",
            },
            {
              property: "og:description",
              content: "Terms and Conditions",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    components: {
      default: Authenticated,
    },
    children: [
      {
        path: "dashboard",
        name: "dash.main",
        components: {
          default: Dashboard,
          footer: Footer,
          nav: NavDrawer,
          appBar: AppBar,
        },
        meta: {
          title: "truID Dashboard",
          metaTags: [
            {
              name: "description",
              content: "Dashboard",
            },
            {
              property: "og:description",
              content: "Dashboard",
            },
          ],
        },
        // meta: { transition: "slide-right" },
      },
      {
        path: "accounts",
        name: "dash.accounts",
        components: {
          default: Accounts,
          footer: Footer,
          nav: NavDrawer,
          appBar: AppBar,
        },
        meta: {
          title: "truID Dashboard",
          metaTags: [
            {
              name: "description",
              content: "Dashboard",
            },
            {
              property: "og:description",
              content: "Dashboard",
            },
          ],
        },
        // meta: { transition: "slide-right" },
      },
      {
        path: "service-providers",
        name: "dash.service-providers",
        components: {
          default: ServiceProviders,
          footer: Footer,
          nav: NavDrawer,
          appBar: AppBar,
        },
        meta: {
          title: "truID Dashboard",
          metaTags: [
            {
              name: "description",
              content: "Dashboard",
            },
            {
              property: "og:description",
              content: "Dashboard",
            },
          ],
        },
        // meta: { transition: "slide-right" },
      },
      {
        path: "help",
        name: "dash.help",
        components: {
          default: Help,
          footer: Footer,
          nav: NavDrawer,
          appBar: AppBar,
        },
        meta: {
          title: "truID Dashboard",
          metaTags: [
            {
              name: "description",
              content: "Dashboard",
            },
            {
              property: "og:description",
              content: "Dashboard",
            },
          ],
        },
        // meta: { transition: "slide-right" },
      },
      {
        path: "profile",
        name: "dash.profile",
        components: {
          default: Profile,
          footer: Footer,
          nav: NavDrawer,
          appBar: AppBar,
        },
        meta: {
          title: "truID Dashboard",
          metaTags: [
            {
              name: "description",
              content: "Dashboard",
            },
            {
              property: "og:description",
              content: "Dashboard",
            },
          ],
        },
        // meta: { transition: "slide-right" },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});
const redirectToLogin = (route: Route) => {
  const LOGIN = "unauthed.landing";
  if (route.name != LOGIN) {
    return {
      name: LOGIN,
      replace: true,
      query: {
        redirectFrom: route.fullPath != "/" ? route.fullPath : undefined,
      },
    };
  }
};

const redirectToHome = (route: Route) => {
  const DEFAULT = "dash.main";
  return { name: DEFAULT, replace: true };
};

router.beforeEach(async (to, from, next) => {
  if (AuthModule.getToken == null) {
    AuthModule.getTokenFromStorage();
  }
  console.log("Before each : ", from, to, AuthModule.isLoggedIn);
  const requiresAuth = to.matched.some(
    (route) => route.meta && route.meta.requiresAuth
  );

  if (AuthModule.isLoggedIn == false) {
    console.log("Not logged in");
    if (to.fullPath === "/") {
      console.log("Redirect /");
      return next(redirectToLogin(to));
    }
    if (requiresAuth) {
      console.log("Redirect to Login");
      return next(redirectToLogin(to));
    } else {
      console.log("Next");
      next();
    }
  } else {
    console.log("Logged in");
    if (to.fullPath === "/") {
      console.log("Redirect to /");
      return next(redirectToHome(to));
    }
    if (requiresAuth) {
      console.log("Just next");
      next();
    } else {
      console.log("Redirect to home ");
      return next(redirectToHome(to));
    }
  }
});
export default router;
