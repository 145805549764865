
import { DashboardLib } from "@/helpers";
import AccountLinkingModule from "@/store/modules/AccountLinking";
import { Component, Vue, Watch } from "vue-property-decorator";

import { SocketTopicResponse } from "truid-common";

import Refresh from "./RefreshModal.vue";
import TwoFactor from "./2faModal.vue";
// import Redirect from "./Redirect.vue";
import ConsentModal from "./FollowLeadModal.vue";
import Login from "./LoginModal.vue";
import DeleteAccount from "./DeleteAccountModal.vue";
import AccountLinking from "./AccountLinkingModal.vue";

// Components
import Overview from "@/components/Main/Overview.vue";
import BankSelection from "@/components/AccountLinking/BankSelection/BankSelection.vue";
import BankLogin from "@/components/AccountLinking/BankLogin/BankLogin.vue";
import Consent from "@/components/AccountLinking/Consent/Consent.vue";
// 2fa
import OTP from "@/components/AccountLinking/Authenticator/2fa/OTP/OTP.vue";
import Surephrase from "@/components/AccountLinking/Authenticator/2fa/Surephrase/Surephrase.vue";
import MobileAuth from "@/components/AccountLinking/Authenticator/2fa/MobileAuth/MobileAuth.vue";
import ProcessingStatus from "@/components/AccountLinking/Authenticator/ProcessingStatus/ProcessingStatus.vue";
import AccountSelection from "@/components/AccountLinking/Authenticator/AccountSelection/AccountSelection.vue";
import GetStarted from "@/components/AccountLinking/GetStarted/GetStarted.vue";
import Error from "@/components/AccountLinking/Authenticator/Error/Error.vue";
import Timeout from "@/components/AccountLinking/Timeout/Timeout.vue";

import Cancelled from "@/components/Main/Cancelled.vue";
import Dashboard from "@/store/modules/Dashboard";
import store from "@/store";
import { PAGE, SocketFlow } from "@/models/nav";

@Component({
  components: {
    Refresh,
    TwoFactor,
    AccountLinking,
    // FollowLead,
    Login,
    DeleteAccount,

    // old ones
    Overview,
    GetStarted,
    Consent,
    ConsentModal,
    BankSelection,
    BankLogin,
    AccountSelection,
    OTP,
    Surephrase,
    MobileAuth,
    ProcessingStatus,
    // Login,
    Cancelled,
    Error,
    Timeout,
  },
})
export default class RefreshFlow extends Vue {
  // @PropSync("refreshFlow") obj!: RefreshFlowType;
  get refreshFlow() {
    return Dashboard.getRefreshFlow;
  }
  set refreshFlow(val) {
    Dashboard.setRefreshFlow(val);
  }
  // @Prop() refreshing!: boolean;
  emitNew() {
    this.triggerRefreshProcess();
    // this.$emit("refresh");
  }
  async emitRefresh() {
    await this.triggerRefreshProcess();
    // this.$emit("refresh");
  }
  get getLastStatus() {
    return Dashboard.getLastStatus;
  }
  get getAccComp() {
    if (this.refreshFlow.currentComp) {
      console.log("Ylo");
    }
    if (store.hasModule(["Dashboard/AccountLinking"])) {
      return store.getters["Dashboard/AccountLinking/getCurrentComponent"];
    }
    if (store.hasModule(["Dashboard"])) {
      return store.getters["Dashboard/AccountLinking/getCurrentComponent"];
    }
    return null;
  }
  @Watch("getLastStatus")
  onStatusChanged(val: SocketTopicResponse | null) {
    if (val) {
      this.refreshFlow.currentComp =
        store.getters["Dashboard/AccountLinking/getCurrentComponent"];
    }
  }
  @Watch("getAccComp")
  onAccChanged(val: PAGE | null) {
    if (val) {
      this.refreshFlow.currentComp = val;
    }
  }
  @Watch("refreshFlow.open")
  async close(val?: boolean) {
    if (val == false) {
      if (store.hasModule(["Dashboard", "AccountLinking"])) {
        await store.dispatch(
          "Dashboard/AccountLinking/clear",
          "Dashboard/AccountLinking"
        );
      }
    }
    // if ()
  }
  async triggerRefreshProcess() {
    // DO A POPULATE -> COLLECTION ID
    try {
      this.refreshFlow.refreshing = true;
      const sel: SocketFlow = "Dashboard/AccountLinking";
      if (store.hasModule(sel) == true) {
        await store.dispatch(
          "Dashboard/AccountLinking/clear",
          "AccountLinking"
        );
        store.unregisterModule(["Dashboard", "AccountLinking"]);
      }

      // if (store.hasModule(sel) == false) {
      store.registerModule(
        ["Dashboard", "AccountLinking"],
        AccountLinkingModule
      );
      // }
      await DashboardLib.populateData("Dashboard/AccountLinking");
      if (store.getters[`Dashboard/AccountLinking/getCollectionID`]) {
        // await CollectionLib.loadCollection("Dashboard/AccountLinking");
        // check last status here to determine where should be
        // this.refreshFlow.currentComp = "AccountLinking";
        this.refreshFlow.currentComp = "GetStarted";
        this.refreshFlow.open = true;
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshFlow.refreshing = false;
    }
  }
}
