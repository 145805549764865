
import { Component, Vue, Prop } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import AccountLinking from "@/store/modules/AccountLinking";
import { FieldValidationLib, CollectionLib, SocketLib } from "@/helpers";
import DataProvidersModule from "@/store/modules/DataProviders";
import { DataProvider } from "@/models/dataProviders";
import User from "@/store/modules/User";
import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";
import { SocketTopicResponse } from "truid-common";
import {
  Collection,
  // stompClient,
  DetailsAccountSelection,
  SocketChallenge,
  SocketPayload,
  SocketPayloadChallenge,
  StompClient,
} from "truid-common";

@Component
// ({
//   filters: {
//     bankIcon(val: string) {
//       if (val.includes("absa")) {
//         return "absa";
//       } else if (val.includes("fnb")) {
//         return "fnb";
//       } else if (val.includes("standardbank")) {
//         return "standardbank";
//       } else if (val.includes("capitec")) {
//         return "capitec";
//       } else if (val.includes("nedbank")) {
//         return "nedbank";
//       }
//     },
//   },
// })
export default class BankLoginCard extends Vue {
  @Prop() flow!: SocketFlow;
  credentials: {
    [key: number]: any;
  }[] = [];
  updateValue(e, index, type) {
    var str = type;
    this.credentials[index] = { [str]: e ? e.trim() : e };
  }
  // username: string | null = null;
  // password: string | null = null;
  reveal = false;
  loading = false;
  captcha = null;
  passwordStatusTerms = ["invalid", "incorrect"];

  rules = {
    required: (value: string | null) => {
      if (value == null || value == undefined || value.length == 0) {
        return "Required";
      } else return true;
    },
  };
  get getLastStatus(): SocketTopicResponse | null {
    return store.getters[`${this.flow}/Socket/getLastStatus`];
  }
  deactivated() {
    this.credentials = [];
  }
  get getCaptchaRequired() {
    const challenge = this.getChallenge;
    return challenge && challenge["challenge-type"] == "captcha" ? true : false;
  }
  get getChallengeDetails() {
    return store.getters[`${this.flow}/getChallenge`].details;
  }
  async goBack() {
    if (this.getLockedAtLogin == true) {
      //create new collection
      await store.dispatch(`${this.flow}/clear`, this.flow);
      store.commit(`${this.flow}/setCurrentComponent`, PAGE.GetStarted);
    } else {
      store.commit(`${this.flow}/setCurrentComponent`, PAGE.BankSelection);
    }
  }
  get passwordError() {
    let error = false;
    if (this.getLastStatus && this.getLastStatus.message) {
      this.passwordStatusTerms.forEach((element) => {
        if (
          this.getLastStatus &&
          this.getLastStatus.message.toLowerCase().includes(element)
        )
          error = true;
      });
    }
    return error;
  }
  get selectedDataProvider(): DataProvider | null {
    return store.getters[`${this.flow}/getSelectedDataProvider`];
  }
  set selectedDataProvider(val: DataProvider | null) {
    store.commit(`${this.flow}/setSelectedDataProvider`, val);
  }
  get getRules() {
    return FieldValidationLib.rules;
  }
  get getLockedAtLogin() {
    return store.getters[`${this.flow}/getLockedAtLogin`];
  }
  async linkAccount() {
    try {
      this.loading = true;
      console.log("Link account");
      store.commit(`${this.flow}/setLockedAtLogin`, true);
      // AccountLinking.setCurrentComponent("AccountSelection");
      const pass = this.credentials.find((e) => e["password"] || e["pin"]);
      const username = this.credentials.find((e) => e["username"]);
      let newBod: Record<string, any> = {};
      for (var i = 0; i < this.credentials.length; i++) {
        newBod = {
          ...newBod,
          ...this.credentials[i],
        };
      }

      if (pass && username) {
        await CollectionLib.login(newBod, this.flow);
        if (this.getCaptchaRequired == true) {
          this.submitChallenge();
        }
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  get getCollectionID(): string | null {
    if (store.getters[`${this.flow}/getCollectionID`])
      return store.getters[`${this.flow}/getCollectionID`];
    else return null;
  }
  get getCollection(): Collection | null {
    if (store.getters[`${this.flow}/getCollection`])
      return store.getters[`${this.flow}/getCollection`];
    else return null;
  }
  get getChallenge(): SocketChallenge | null {
    if (store.getters[`${this.flow}/getChallenge`])
      return store.getters[`${this.flow}/getChallenge`];
    else return null;
  }
  submitChallenge() {
    console.log("Submit challenge");
    if (this.getChallenge && this.getCollectionID && this.getCollection) {
      const temp: SocketPayloadChallenge = {
        "challenge-id": this.getChallenge.id,
        response: [this.captcha],
      };
      const payload: SocketPayload = {
        name: "challenge-response",
        "collection-id": this.getCollectionID,
        "wss-token": this.getCollection["wss-token"],
        payload: temp,
      };
      const st: StompClient | null = store.getters[`${this.flow}/getStomp`];
      st?.send(payload);
      store.commit(`${this.flow}/setChallenge`, null);
      store.commit(`${this.flow}/setChallengeID`, null);

      // NavModule.setCurrentComponent(PAGE.Processing);
    }
  }
}
