
import { Component, Vue, Prop } from "vue-property-decorator";
import ProcessingStatusCard from "./ProcessingStatusCard.vue";
import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    ProcessingStatusCard,
  },
})
export default class ProcessingStatus extends Vue {
  @Prop() flow!: SocketFlow;
}
