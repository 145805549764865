
import DashboardModule from "@/store/modules/Dashboard";
import { Component, Vue } from "vue-property-decorator";
import Ranking from "./Ranking.vue";
@Component({
  components: {
    Ranking,
  },
})
export default class Expenses extends Vue {
  get getData() {
    return this.getExpenses.sort((a, b) =>
      a.total > b.total ? 1 : a.total < b.total ? -1 : 0
    );
  }
  get getExpenses() {
    return DashboardModule.getDashboardData != null
      ? DashboardModule.getDashboardData["json-data"].rankings
      : [];
  }
}
