
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import DashboardModule from "@/store/modules/Dashboard";
import CompleteYourProfile from "@/components/CompleteProfile/CompleteYourProfile.vue";
import OverviewPanels from "@/components/Main/overview/OverviewPanels/OverviewPanels.vue";

import Expenses from "@/components/Main/overview/Expenses/Expenses.vue";

import OfferSection from "@/components/Main/overview/offers/OfferSection.vue";
import RefreshFlow from "@/components/Main/overview/refresh/RefreshFlow.vue";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DashboardLib } from "@/helpers";
dayjs.extend(customParseFormat);

@Component({
  components: {
    CompleteYourProfile,
    OverviewPanels,
    Expenses,
    OfferSection,
    RefreshFlow,
  },
  metaInfo() {
    return {
      title: "Dashboard",
    };
  },
})
export default class Overview extends Vue {
  get getInfo() {
    return AuthModule.getTokenInfo;
  }
  get canRefresh() {
    if (this.getRefreshDate != "[x]") {
      if (dayjs(this.getRefreshDate, "DD-MMM-YYYY") < dayjs()) {
        return true;
      }
    }
    return false;
  }
  get getRefreshDate() {
    return DashboardModule.getDashboardData != null
      ? DashboardModule.getDashboardData.expires
      : "[x]";
  }
  get getUpdatedDate() {
    return DashboardModule.getDashboardData != null
      ? DashboardModule.getDashboardData.date
      : "[x]";
  }
  async mounted() {
    if (AuthModule.getTokenInfo && AuthModule.getTokenInfo.exists == "true") {
      await DashboardLib.getData();
    }
  }
  async activated() {
    if (AuthModule.getTokenInfo && AuthModule.getTokenInfo.exists == "true") {
      await DashboardLib.getData();
    }
  }
}
