
import { Component, Vue, Watch } from "vue-property-decorator";
@Component
export default class ServiceProviders extends Vue {
  e6 = 1;
  openFilter = false;
  serviceProviders = [
    {
      icon: "mdi-bank",
      text: "Fnb",
      color: "light-blue",
    },
    {
      icon: "mdi-bank",
      text: "Absa",
      color: "red",
    },
    {
      icon: "mdi-bank",
      text: "Standard Bank",
      color: "blue",
    },
    {
      icon: "mdi-bank",
      text: "Capitec",
      color: "grey",
    },
    {
      icon: "mdi-bank",
      text: "Nedbank",
      color: "green",
    },
  ];
}
