import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { PAGE, SocketFlow } from "@/models/nav";
import { lead, populateDashboardData } from "@/models/dashboard";
import {
  Collection,
  SocketChallenge,
  StompClient,
  SocketTopicResponse,
} from "truid-common";
import { DataProvider } from "@/models/dataProviders";

@Module({
  stateFactory: true,
  namespaced: true,
})
class AccountLinking extends VuexModule {
  collection: Collection | null = null;
  lockAtLogin = false;
  stomp: StompClient | null = null;
  dataProviders: DataProvider[] = [];
  selectedDataProvider: DataProvider | null = null;
  challengeID: string | null = null;
  challenge: SocketChallenge | null = null;
  currentComponent: PAGE | null = null;
  collectionID: string | null = null;
  // currentStatusMessage: string | null = null;
  currentStatusMessage: {
    title: string | null;
    message: string | null;
  } = {
    title: null,
    message: null,
  };
  get getLockedAtLogin() {
    return this.lockAtLogin;
  }
  @Mutation
  setLockedAtLogin(val: boolean) {
    this.lockAtLogin = val;
  }
  get getCollectionID() {
    return this.collectionID;
  }
  @Mutation
  setCollectionID(val: string | null) {
    this.collectionID = val;
  }
  get getLastStatus() {
    const temp: SocketTopicResponse | undefined =
      this.context.getters["Socket/getLastStatus"];
    if (temp) return temp;
    return null;
  }
  get getStomp() {
    return this.stomp;
  }
  @Mutation
  setStomp(val: StompClient | null) {
    this.stomp = val;
  }

  get getCollection() {
    return this.collection;
  }
  @Mutation
  setCollection(val: Collection | null) {
    this.collection = val;
  }
  get getCurrentComponent() {
    return this.currentComponent;
  }
  @Mutation
  setCurrentComponent(val: PAGE | null) {
    this.currentComponent = val;
  }
  get getStatusMessage() {
    return this.currentStatusMessage;
  }
  @Mutation
  setStatusMessage(val: { title: string | null; message: string | null }) {
    this.currentStatusMessage = val;
  }

  get getDataProviders(): DataProvider[] {
    return this.dataProviders;
  }
  @Mutation
  setDataProviders(val: DataProvider[]) {
    this.dataProviders = val;
  }
  get getSelectedDataProvider(): DataProvider | null {
    return this.selectedDataProvider;
  }
  @Mutation
  setSelectedDataProvider(val: DataProvider | null) {
    this.selectedDataProvider = val;
  }
  get getChallengeID(): string | null {
    return this.challengeID;
  }
  @Mutation
  setChallengeID(val: string | null) {
    this.challengeID = val;
  }
  get getChallenge(): SocketChallenge | null {
    return this.challenge;
  }
  @Mutation
  setChallenge(val: SocketChallenge | null) {
    this.challenge = val;
  }
  @Action
  async clear(flow: SocketFlow) {
    // clear sockets
    const module = flow.split("/");
    module.push("Socket");

    if (store.hasModule(module) == true) {
      console.log("Account linking clear inner");
      await this.context.dispatch("Socket/disconnect");
      store.unregisterModule(module);
    }
    this.context.commit("setChallenge", null);
    this.context.commit("setCollection", null);
    this.context.commit("setCollectionID", null);
    this.context.commit("setSelectedDataProvider", null);
    this.context.commit("setDataProviders", []);
    this.context.commit("setStomp", null);
    this.context.commit("setStatusMessage", null);
    return Promise.resolve();
  }
}
// export { AccountLinking };
export default AccountLinking;
