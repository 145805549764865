
import { Component, Vue, Watch } from "vue-property-decorator";
import UserModule from "@/store/modules/User";

@Component
export default class Cancelled extends Vue {
  loading = false;
  redirectToCompanySite() {
    console.log("Redirect to company site");
  }
  get getBrand() {
    return UserModule.getBrand;
  }
}
