
import { DashboardLib } from "@/helpers";
import { sliderCalcParams } from "@/models/dashboard";
import Dashboard from "@/store/modules/Dashboard";
import Slider from "@/store/modules/Slider";
import { Component, Vue, Watch } from "vue-property-decorator";
const DURATION = {
  day: 30,
  month: 1,
  year: 1 / 12,
};

// Convertion duration to instalments
const PAYMENT = {
  day: 1,
  month: 30,
  year: 365,
};

// NCA maximum initiation fee
const MAX = 1050;

// Unit of rounding for maximum loan
const ROUND = 50;

// Standard parameters
let PARAMS: sliderCalcParams = {
  fixed: 165, // Fixed initiation fee according to NCA
  var: 0.1, // Variable fee over loan amount - threshold
  threshold: 1000, // Threshold loan amount under which no variable fee
  vat: 0.15, // VAT on initiation fee
  service: 50, // Monthly service fee on < 1 month, how to?
  interest: 0.0016, // Interest per period
  period: "day", // Interest period (day, month, year)
  payment: "month", // Maximum frequency for instalments
};
@Component
export default class AdjustLoanSlider extends Vue {
  refocusSlider() {
    const ref = this.$refs.offerSlider;
    const y =
      (ref as Element).getBoundingClientRect().top + window.pageYOffset - 130;
    if (ref) {
      window.scrollTo({ top: y, behavior: "smooth" });
      // (ref as any).scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  get getInstalments() {
    // return 60 >= this.value ? 1 : Math.floor((this.value - 30) / 30);
    return Slider.getInstalments;
  }
  get getValue() {
    return Slider.getValue;
    // if (61 > this.value) {
    //   return Math.floor(this.value / 2);
    // } else {
    //   return Math.floor((this.value - 30) / 30);
    // }
  }
  get getCurrentSpread() {
    // const val = this.getInstalments * 30;
    // return this.spread[val];
    return Slider.getCurrentSpread;
  }
  get getDashboardData() {
    return Dashboard.getDashboardData;
  }
  get getAffordability() {
    if (this.getDashboardData) {
      if (this.getDashboardData["json-data"].cumulatives.affordability) {
        if (
          this.getDashboardData["json-data"].cumulatives.affordability.average >
          0
        )
          return this.getDashboardData["json-data"].cumulatives.affordability
            .average;
      }
    }
    return 0;
  }
  @Watch("getAffordability", {
    immediate: true,
    deep: true,
  })
  onAffordabilityChange(val: number) {
    console.log("Calculate affordability ", val);
    this.spread = this.calculate(val, this.durations, PARAMS);
  }
  get spread() {
    return Slider.getSpread;
  }
  set spread(val) {
    Slider.setSpread(val);
  }
  get value() {
    return Slider.getSlider;
  }
  set value(val: number) {
    Slider.setSlider(val);
  }
  durations = [30, 30 * 2, 30 * 3, 30 * 4, 30 * 5, 30 * 6];
  // spread: any = {};
  // async mounted() {
  //   this.initSliderStuff();
  //   // await this.loadLeads(this.getInstalments);
  // }
  initSliderStuff() {
    // value == duration
    if (this.getAffordability > 0) {
      this.spread = this.calculate(
        this.getAffordability,
        this.durations,
        PARAMS
      );
    } else {
      this.spread = {
        // 0: 0,
        // 1: 0,
        // 2: 0,
        // 3: 0,
        // 4: 0,
        // 5: 0,
      };
    }
  }
  reversed(
    affordability: number,
    dur: number,
    rate: number,
    params: sliderCalcParams
  ) {
    return (
      Math.floor(
        (dur * affordability - dur * params.service * (1 + params.vat)) /
          (1 + dur * rate) -
          165
      ) /
      (1 + params.vat)
    );
  }
  calculate(affordability: number, durations: number[], params) {
    let rate = params.interest * DURATION[params.period];

    let amounts = {};
    for (let i = 0; i < durations.length; i++) {
      let instalments = Math.ceil(durations[i] / PAYMENT[params.payment]);
      let loan =
        Math.floor(
          this.reversed(affordability, instalments, rate, params) / ROUND
        ) * ROUND;

      amounts[durations[i]] = loan;
    }

    return amounts;
  }
  // async loadLeads(installments: number) {
  //   try {
  //     await DashboardLib.getAvailLeads(installments);
  //   } catch (err) {
  //     console.error(err);
  //     return Promise.reject(err);
  //   }
  // }
}
