
import { Component, Vue, Prop } from "vue-property-decorator";
import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";

@Component
export default class Error extends Vue {
  @Prop() flow!: SocketFlow;
  viewError = false;
  goToLogin() {
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.BankLogin);
  }
  get getLastStatus() {
    // return SocketModule.getLastStatus;
    return store.getters[`${this.flow}/Socket/getLastStatus`];
  }
}
