
import { Component, Vue, Prop } from "vue-property-decorator";
import { SocketFlow } from "@/models/nav";
import BankLoginCard from "@/components/AccountLinking/BankLogin/BankLoginCard.vue";
import SecurityGuarentee from "@/components/AccountLinking/SecurityGuarentee.vue";

@Component({
  components: {
    BankLoginCard,
    SecurityGuarentee,
  },
})
export default class BankLogin extends Vue {
  @Prop() flow!: SocketFlow;

  async goBack() {
    const temp = this.$refs.banklogin;
    if (temp) {
      (temp as BankLoginCard).goBack();
    }
  }
}
