import Vue from "vue";
import Vuetify from "vuetify";
import { colors } from "vuetify/lib";
import AbsaIcon from "@/assets/banks/AbsaIcon.vue";
import NedbankIcon from "@/assets/banks/NedbankIcon.vue";
import FnbIcon from "@/assets/banks/FnbIcon.vue";
import StandardbankIcon from "@/assets/banks/StandardbankIcon.vue";
import TruidIcon from "@/assets/banks/TruidIcon.vue";
import CapitecIcon from "@/assets/banks/CapitecIcon.vue";
import SpinnerIcon from "@/assets/icons/SpinnerIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      absa: {
        component: AbsaIcon,
      },
      fnb: {
        component: FnbIcon,
      },
      standardbank: {
        component: StandardbankIcon,
      },
      nedbank: {
        component: NedbankIcon,
      },
      truidIcon: {
        component: TruidIcon,
      },
      capitec: {
        component: CapitecIcon,
      },
      spinner: {
        component: SpinnerIcon,
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 900,
      md: 1300,
      lg: 1600,
    },
    mobileBreakpoint: "xs",
    scrollBarWidth: 20,
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#242423",
        primaryFaded: "#000000",
        primaryButton: "#03082E",
        secondary: "#F1ECF2",
        accent: "#000000",
        error: "#FF4848",
        warning: colors.orange.base,
        info: colors.blue.base,
        success: "#000000",
        background: colors.grey.darken2,
        appBar: colors.grey.darken3,
        truid: "#C4D82E",
        appBg: "#F4F6F4",
        textfieldBg: "#F3F3F3",
        earningsComingSoon: "#707C13",
        obligations: {
          base: "#7356BF",
          lighten1: "#EEECF7",
        },
        livingExpenses: {
          base: "#276EF1",
          lighten1: "#E7EEFC",
        },
        discretionary: {
          base: "#EB7567",
          lighten1: "#FAEFEE",
        },
      },
    },
  },
});
