
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class NotFound extends Vue {
  enteredCollectionId: string | null = null;
  loading = false;

  async loadWithEnteredCollection(): Promise<void> {
    console.log("Load with collection");
    try {
      this.loading = true;
      if (this.enteredCollectionId != null) {
        this.$router.push({
          name: "mainFlow",
          params: {
            id: this.enteredCollectionId,
          },
        });
      }
    } catch (err) {
      alert("Error redirecting");
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
