
// import AccountLinking from "@/store/modules/AccountLinking";
import { cumulative } from "@/models/dashboard";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class OverviewPanel extends Vue {
  @Prop() data!: cumulative;
  @Prop({
    default: false,
  })
  comingSoon!: boolean | undefined;
  @Prop() title!: "affordability" | "earnings" | "income" | "expenses";

  get getTitle() {
    switch (this.title) {
      case "earnings": {
        return this.comingSoon
          ? "This will show how much you have earned from qualifying and  approved applications."
          : "What I earned sharing my data ";
      }
      case "income": {
        return "What I have earned this month";
      }
      case "affordability": {
        return "What I can afford to pay monthly";
      }
      case "expenses": {
        return "Spend affecting my affordability";
      }
      default: {
        return "Undefined";
      }
    }
  }
  get getIcon() {
    if (this.data.average == 0 && this.data.delta == 0) {
      switch (this.title) {
        case "earnings": {
          return "mdi-wallet-outline";
        }
        case "affordability": {
          return "mdi-check";
        }
        default: {
          return "mdi-alert";
        }
      }
    } else if (this.data.delta == 0) {
      return "mdi-check";
    } else if (this.data.delta > 0) {
      return "mdi-arrow-up";
    } else return "mdi-arrow-down";
  }
  get getText() {
    if (this.data.average == 0 && this.data.delta == 0) {
      switch (this.title) {
        case "earnings": {
          return "Get cash back on qualifying approved credit applications ";
        }
        default:
          return "No defined text";
      }
    } else if (this.data.delta == 0) {
      switch (this.title) {
        case "income": {
          return `Your ${this.title} has not changed over the past [x] months`;
        }
        default:
          return `Your ${this.title} has not changed from last month`;
      }
    } else if (this.data.delta > 0) {
      switch (this.title) {
        case "affordability": {
          return `Your ${this.title} has improved by R${Math.floor(
            this.data.delta
          )} from last month`;
        }
        default:
          return `Your ${this.title} has increased by R${Math.floor(
            this.data.delta
          )} from last month`;
      }
    } else {
      switch (this.title) {
        // case "affordability": {
        //   return `Your ${this.title} has improved by ${this.data.delta} from last month`;
        // }
        default:
          return `Your ${this.title} has decreased by R${Math.floor(
            this.data.delta * -1
          )} from last month`;
      }
    }
  }
  get getIconColor() {
    if (this.comingSoon) {
      return "earningsComingSoon";
      // earningsComingSoon,
    } else if (
      this.data.average == 0 &&
      this.data.delta == 0 &&
      this.title == "earnings"
    ) {
      return "light-green";
    } else if (this.data.delta == 0) {
      return "blue";
    } else if (this.data.delta > 0) {
      return this.title == "expenses" ? "red" : "green";
    } else return this.title == "expenses" ? "green" : "red";
  }
}
