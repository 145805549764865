
import { Component, Vue, Prop } from "vue-property-decorator";
import MobileAuthCard from "./MobileAuthCard.vue";
import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    MobileAuthCard,
  },
})
export default class MobileAuth extends Vue {
  @Prop() flow!: SocketFlow;
  goBack() {
    // cancel web socket flow -> Modal for cancel
  }
}
