
import { Component, Vue } from "vue-property-decorator";
import Maintenance from "@/components/Maintenance.vue";
import AuthModule from "./store/modules/Auth";
import GlobalNotifications from "@/components/GlobalNotifications.vue";
import Nav from "./store/modules/Nav";

@Component({
  components: {
    Maintenance,
    GlobalNotifications,
  },
  metaInfo(this: App) {
    return {
      title: "truID",
      titleTemplate: "%s | truID Portal",
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "truID Portal",
        },
      ],
    };
  },
})
export default class App extends Vue {
  get getMaintenanceMode() {
    return Nav.getMaintenance;
  }
  // beforeDestroy() {
  //   alert("before destroy");
  //   if (AuthModule.getToken) {
  //     alert("Has token before destroy");
  //     AuthModule.setLocalStorage(AuthModule.getToken);
  //   }
  // }
}
