
import { Component, Vue, Prop } from "vue-property-decorator";
import UserModule from "@/store/modules/User";
import NavModule from "@/store/modules/Nav";
import { dataProvidersLib } from "@/helpers/dataProviders";
import DataProvidersModule from "@/store/modules/DataProviders";
import Timer from "@/components/Timer.vue";
import dayjs from "dayjs";
import { PAGE, SocketFlow } from "@/models/nav";
import { axiosInstance, axiosOldInstance } from "@/plugins/axios";
import {
  Collection,
  StompClient,
  DetailsPassword,
  SocketChallenge,
  SocketPayload,
  SocketPayloadChallenge,
} from "truid-common";
import User from "@/store/modules/User";
import store from "@/store";

@Component({
  filters: {
    // bankName: (val: string | undefined) => {
    //   if (val == undefined) return "Undefined";
    //   switch(val) {
    //   }
    // }
  },
  components: {
    Timer,
  },
})
export default class SurephraseCard extends Vue {
  @Prop() flow!: SocketFlow;
  valid = false;
  loading = false;
  requiredPhrase: string[] = [];
  timeLimit = 150;
  timerInterval: number | null = null;
  timeStarted: number | null = null;
  currentTime = dayjs().valueOf();
  get validLength() {
    let count = 0;
    this.requiredPhrase.forEach((val) => {
      if (val) {
        count++;
      }
    });
    if (this.getChallengeDetails == null) return false;
    return count < this.getChallengeDetails.positions.length;
  }
  get getLength() {
    if (this.getChallengeDetails) {
      return this.getChallengeDetails.length;
    } else {
      return 0;
    }
  }
  goBack() {
    console.log("Go back? ");
  }
  get rules() {
    return {
      required: (value: string | null) => {
        if (value == null || value == undefined || value.length == 0) {
          return "Required";
        } else return true;
      },
      // (value != null && value != undefined) || "Required.",
      counter: (value: string) => {
        if (value != null) {
          return value.length == 4 || "Requires 4 characters";
        } else return "Required";
      },
    };
  }
  checkNextField(data: any, index: number) {
    console.log("Check next field ", data, index);
    var i = Number(index);
    if (this.getChallengeDetails) {
      if (this.getChallengeDetails.positions.includes(i)) {
        if (data.length == 0) {
          if (this.getChallengeDetails.positions.indexOf(i) - 1 >= 0) {
            var prev =
              "sure_" +
              this.getChallengeDetails.positions[
                this.getChallengeDetails.positions.indexOf(i) - 1
              ];
            (this.$refs[prev] as HTMLInputElement[])[0].focus();
          }
        } else if (data.length > 0) {
          if (
            this.getChallengeDetails.positions.indexOf(i) + 1 <
            this.getChallengeDetails.positions.length
          ) {
            var next =
              "sure_" +
              this.getChallengeDetails.positions[
                this.getChallengeDetails.positions.indexOf(i) + 1
              ];
            console.log("Next ", next);
            (this.$refs[next] as HTMLInputElement[])[0].focus();
          }
        }
      }
    }
  }
  mounted() {
    this.checkTimeOnMounted();
    this.startTimer();
  }
  startTimer() {
    this.timerInterval = setInterval(
      () => (this.currentTime = dayjs().valueOf()),
      1000
    );
  }
  onTimesUp() {
    if (this.timerInterval != null) clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.$emit("error", "Timed out waiting for user input.");
  }
  restartTimer() {
    if (this.timerInterval != null) clearInterval(this.timerInterval);
    this.checkTimeOnMounted();
    this.startTimer();
  }
  submitChallenge() {
    console.log("Submit challenge");
    if (this.getChallenge && this.getCollectionID && this.getCollection) {
      var newArray = "";
      // var newArray: string[] = [];
      for (var val in this.requiredPhrase) {
        if (val != undefined) {
          newArray += this.requiredPhrase[val].toString();
        }
      }
      const temp: SocketPayloadChallenge = {
        "challenge-id": this.getChallenge.id,
        response: [newArray.toString()],
      };
      const payload: SocketPayload = {
        name: "challenge-response",
        "collection-id": this.getCollectionID,
        "wss-token": this.getCollection["wss-token"],
        payload: temp,
      };
      // stompClient.send(payload);
      const st: StompClient | null = store.getters[`${this.flow}/getStomp`];
      st?.send(payload);
      store.commit(`${this.flow}/setChallenge`, null);
      store.commit(`${this.flow}/setChallengeID`, null);
      // User.setChallenge(null);
      // User.setChallengeID(null);
      // NavModule.setCurrentComponent(PAGE.Processing);
    }
  }
  async submitOTP() {
    console.log("Submit OTP");
    try {
      this.loading = true;
      var newArray = "";
      for (var val in this.requiredPhrase) {
        if (val != undefined) {
          newArray += this.requiredPhrase[val].toString();
        }
      }
      const body = {
        response: newArray,
      };
      console.log("Test surephrase ", body);
      if (this.getChallenge) {
        const res = await axiosOldInstance.put(
          "/collections/" +
            this.getCollectionID +
            "/challenges/" +
            this.getChallenge.id,
          body
        );
        console.log("Surephrase response ", res);
      }
    } catch (err) {
      console.log("Surephrase error", err);
      // NavModule.setError({
      //   code: 509,
      //   message: JSON.stringify(err),
      // });
    } finally {
      this.loading = false;
    }
  }
  get getCollectionID(): string | null {
    if (store.getters[`${this.flow}/getCollectionID`]) {
      return store.getters[`${this.flow}/getCollectionID`];
    }
    return null;
  }
  get getCollection(): Collection | null {
    if (store.getters[`${this.flow}/getCollection`]) {
      return store.getters[`${this.flow}/getCollection`];
    }
    return null;
  }
  get getChallengeDetails(): DetailsPassword | null {
    if (this.getChallenge) {
      return this.getChallenge.details as DetailsPassword;
    }
    return null;
  }
  get getChallenge(): SocketChallenge | null {
    if (store.getters[`${this.flow}/getChallenge`]) {
      return store.getters[`${this.flow}/getChallenge`];
    }
    return null;
  }
  checkTimeOnMounted() {
    if (this.getChallenge) {
      this.timeStarted = dayjs(this.getChallenge.timestamp).toDate().valueOf();
      this.timeLimit = this.getChallenge.timeout / 1000;
    }
  }

  get getSelectedBank() {
    // return DataProvidersModule.getSelectedDataProvider;
    return store.getters[`${this.flow}/getSelectedDataProvider`];
  }
  get formattedTimeLeft() {
    const timeLeft = this.timeLeft;
    const minutes = Math.floor(this.timeLeft / 60);
    let seconds = (timeLeft % 60).toString();

    if (timeLeft % 60 < 10) {
      seconds = `0${timeLeft % 60}`;
    }

    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds}`;
  }
  get timeLeft() {
    if (this.timeStarted != null) {
      const temp =
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000);
      return temp >= 0 ? temp : 0;
    }
    return 0;
  }
}
