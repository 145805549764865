import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import truid from "truid-common";
import { ConfigLib } from "@/helpers/config";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import Hotjar from "vue-hotjar";
import LottieAnimation from "lottie-web-vue";
import Nav from "./store/modules/Nav";

Vue.use(LottieAnimation);
Vue.use(Hotjar, {
  id: "3216355",
  // isProduction: process.env.NODE_ENV == "production" ? true : false,
  isProduction: true,
});
Vue.use(
  VueGtag,
  {
    config: { id: "G-MFWPKDP15X" },
  },
  router
);
Vue.use(VueMeta, {
  // keyName: "metadata",
  // attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  // tagIDKeyName: 'vmid',
  // refreshOnceOnNavigation: true
});

Vue.filter("bankIcon", (val: string) => {
  if (val.includes("absa")) {
    return "$absa";
  } else if (val.includes("fnb")) {
    return "$fnb";
  } else if (val.includes("standardbank")) {
    return "$standardbank";
  } else if (val.includes("capitec")) {
    return "$capitec";
  } else if (val.includes("nedbank")) {
    return "$nedbank";
  } else if (val.includes("livetestapi")) {
    return "$truidIcon";
  } else {
    return "mdi-bank";
  }
});
Vue.config.devtools = true;
Vue.config.productionTip = false;
ConfigLib.checkMaintenance().then((checkMaintenance) => {
  Nav.setMaintenance(checkMaintenance);
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
