
import { Component, Vue, Watch } from "vue-property-decorator";
import UserModule from "@/store/modules/User";

@Component
export default class VerifyIncomeModal extends Vue {
  get open() {
    return UserModule.isVerifyIncomeOpen;
  }
  set open(val: boolean) {
    UserModule.setVerifyIncome(val);
  }
}
