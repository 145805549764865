const Fake = () => {
  const fakeFunction = async () => {
    //just a demo.
  };
  return {
    fakeFunction,
  };
};
const FakeLib = Fake();
export { FakeLib };
