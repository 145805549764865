
import { Component, Vue, Watch } from "vue-property-decorator";
import UserModule from "@/store/modules/User";

@Component
export default class VerifyIdentityModal extends Vue {
  get open() {
    return UserModule.isVerifyIdentityOpen;
  }
  set open(val: boolean) {
    UserModule.setVerifyIdentity(val);
  }
}
