
import { Component, Vue } from "vue-property-decorator";
import { axiosNoAuth } from "@/plugins/axios";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";

@Component
export default class ForgotPassword extends Vue {
  loading = false;
  valid = false;
  username: string | null = null;
  rules = {
    required: (v: string) => !!v || "Cannot be empty",
    email: (v: string) => {
      const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Valid email required";
    },
  };

  async submitCredentials(): Promise<void> {
    try {
      this.loading = true;
      if (this.username != null) {
        const url = "/api/auth/forgot";
        const res = await axiosNoAuth
          .post(url, {
            email: this.username,
          })
          .catch((e) => {
            console.log("Forgot Passowrd error ", e);
            return Promise.reject(e);
          });
        this.username = null;
        const msg = res.data.message as string;
        this.$router.push({
          name: "signin",
        });
      }
      return Promise.resolve();
    } finally {
      this.loading = false;
    }
  }
}
