import { PAGE } from "@/models/nav";
import store from "@/store";
import vuetify from "@/plugins/vuetify";

import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";

@Module({ dynamic: true, namespaced: true, name: "Nav", store })
class Nav extends VuexModule {
  maintenance = false;
  logout = false;
  appDrawer = true;
  get getLogoutPrompt(): boolean {
    return this.logout;
  }
  @Mutation
  setLogoutPrompt(val: boolean) {
    this.logout = val;
  }
  get getAppDrawer(): boolean {
    return this.appDrawer;
  }
  @Mutation
  setAppDrawer(val: boolean) {
    this.appDrawer = val;
  }
  get getMaintenance(): boolean {
    return this.maintenance;
  }
  @Mutation
  setMaintenance(val: boolean) {
    this.maintenance = val;
  }
}

export default getModule(Nav);
