
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title: "Help",
    };
  },
})
export default class Help extends Vue {
  currentProgress = 15;
  tellUs = "";
  reasons = [
    "General issues with the website",
    "I had an issue linking my bank account",
    "Something went wrong with my credit application",
  ];
  reason = this.reasons[0];
  goBack() {
    this.$router.push({
      name: "Main",
    });
  }
}
