
import { Component, Vue, Prop } from "vue-property-decorator";
import GetStartedCard from "@/components/AccountLinking/GetStarted/GetStartedCard.vue";
import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    GetStartedCard,
  },
})
export default class GetStarted extends Vue {
  @Prop() flow!: SocketFlow;
}
