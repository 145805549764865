
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Maintenance extends Vue {
  mounted() {
    document.title = "truID - Maintenance";
  }
  get mainStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case "sm":
        return "90%";
      case "md":
        return "70%";
      case "lg":
        return "60%";
      case "xl":
        return "60%";
      default:
        return "100%";
    }
  }
}
