import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import Dashboard from "./Dashboard";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Slider",
  store,
})
class Slider extends VuexModule {
  sliderCalculated = 16;
  spread: {
    [key: number]: number;
  } = {};

  get getSpread() {
    return this.spread;
  }
  @Mutation
  setSpread(val: { [key: number]: number }) {
    this.spread = val;
    // console.log("Set spread here : ", val);
  }
  get getSlider() {
    return this.sliderCalculated;
  }
  @Mutation
  setSlider(val: number) {
    this.sliderCalculated = val;
  }
  get getInstalments() {
    return 60 >= this.getSlider ? 1 : Math.floor((this.getSlider - 30) / 30);
  }
  get getValue() {
    if (61 > this.getSlider) {
      return Math.floor(this.getSlider / 2);
    } else {
      return Math.floor((this.getSlider - 30) / 30);
    }
  }
  get getCurrentSpread() {
    if (Dashboard.getAffordability > 0) {
      const val = this.getInstalments * 30;
      return this.spread[val];
    }
    return 0;
  }
}
export default getModule(Slider);
