
import { Component, Vue, Prop } from "vue-property-decorator";
import ConsentCard from "@/components/AccountLinking/Consent/ConsentCard.vue";

import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";

@Component({
  components: {
    ConsentCard,
  },
})
export default class Consent extends Vue {
  @Prop() flow!: SocketFlow;
  goBack() {
    const temp = this.$refs.consent;
    if (temp) {
      (temp as ConsentCard).goBack();
    }
  }
}
