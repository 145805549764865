
import { Component, Vue } from "vue-property-decorator";
import VerifyIdentityModal from "@/components/CompleteProfile/VerifyIdentityModal.vue";
import VerifyIncomeModal from "@/components/CompleteProfile/VerifyIncomeModal.vue";
import LogoutPrompt from "@/components/LogoutPrompt.vue";
import UpdatePersonalDetailsModal from "@/components/CompleteProfile/UpdatePersonalDetailsModal.vue";
import Nav from "@/store/modules/Nav";
import ResumeSessionJWT from "@/components/ResumeSessionJWT.vue";
import AccountLinking from "@/store/modules/AccountLinking";
import Dashboard from "@/store/modules/Dashboard";
import store from "@/store";
import RefreshFlow from "@/components/Main/overview/refresh/RefreshFlow.vue";

@Component({
  components: {
    VerifyIdentityModal,
    VerifyIncomeModal,
    UpdatePersonalDetailsModal,
    LogoutPrompt,
    ResumeSessionJWT,
    RefreshFlow,
  },
})
export default class Authenticated extends Vue {
  created() {
    // if (store.hasModule("Dashboard") == false) {
    //   store.registerModule("Dashboard", Dashboard);
    // }
    if (store.hasModule("AccountLinking") == false) {
      store.registerModule("AccountLinking", AccountLinking);
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      Nav.setAppDrawer(false);
    }
  }
  async beforeDestroy() {
    if (store.hasModule("AccountLinking") == true) {
      console.warn(
        "Woop deregister accountlinking before",
        store.hasModule(["AccountLinking", "Socket"])
      );
      await store.dispatch("AccountLinking/clear", "AccountLinking");
      console.warn("Woop deregister accountlinking now ");
      store.unregisterModule("AccountLinking");
    }
    await Dashboard.clear();
    // if (store.hasModule(["Dashboard", "AccountLinking"])) {
    //   console.warn("Woop clear dashboard accountlinking");
    //   console.log("Clear dashboard inner");
    //   await store.dispatch("Dashboard/clear");
    //   store.unregisterModule(["Dashboard", "AccountLinking"]);
    // }
  }
  get promptLogout() {
    return Nav.getLogoutPrompt;
  }
  set promptLogout(val: boolean) {
    Nav.setLogoutPrompt(val);
  }
  get navDrawer() {
    return Nav.getAppDrawer;
  }
  set navDrawer(val: boolean) {
    Nav.setAppDrawer(val);
  }
}
