
import { ranking } from "@/models/dashboard";
import AccountLinking from "@/store/modules/AccountLinking";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Ranking extends Vue {
  @Prop() ranking!: ranking;

  get getColor() {
    switch (this.ranking.name) {
      case "Living expenses": {
        return "livingExpenses";
      }
      case "Obligations": {
        return "obligations";
      }
      case "Discretionary expenses": {
        return "discretionary";
      }
      default:
        return "primary";
    }
  }
  get getTitle() {
    switch (this.ranking.name) {
      case "Discretionary expenses":
        return "Expenses I can control";
      default:
        return this.ranking.name;
    }
  }
  get getIcon() {
    switch (this.ranking.name) {
      case "Living expenses": {
        return "mdi-home";
      }
      case "Obligations": {
        return "mdi-text-box-check";
      }
      case "Discretionary expenses": {
        return "mdi-receipt-text";
      }
      default:
        return "mdi-account";
    }
  }
}
