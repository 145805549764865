import { SocketTopicResponse } from "truid-common";
import User from "@/store/modules/User";
import { DASHBOARD_PAGE, PAGE, SocketFlow } from "@/models/nav";
import Navigation from "@/store/modules/Nav";
import dayjs from "dayjs";
import DataProviders from "@/store/modules/DataProviders";
import Dashboard from "@/store/modules/Dashboard";
import store from "@/store";
import { DashboardLib } from "./dashboard";

const Socket = () => {
  // const disconnect = (flow: SocketFlow) => {

  // }
  const processTopicUpdate = async (
    message: SocketTopicResponse | null,
    flow: SocketFlow
  ) => {
    if (message) {
      console.log("Status ", flow, message);
      switch (message.code) {
        case 2:
          //go to timeout and set message
          if (flow == "Dashboard/AccountLinking")
            Dashboard.setCurrentComponent(DASHBOARD_PAGE.Error);
          else store.commit(`${flow}/setCurrentComponent`, PAGE.Error);
          break;
        case 5:
          // challenge received.
          // {"name":"CHALLENGE","code":5,"timestamp":"2022-03-09T10:40:15.55+02:00","challenge":{"id":"7avuue5npk83jvmioxv8pzh08","timestamp":"2022-03-09T10:40:15.55+02:00","timeout":120000,"challenge-type":"password","details":{"length":9,"positions":[1,3,7],"user-input-type":"ALPHANUMERIC"}},"collection-id":"e63u4qj5n424wzqk994gl6a7a"}
          if (message.challenge) {
            store.commit(`${flow}/setChallenge`, message.challenge);
            // Dashboard.setChallenge(message.challenge);
            // save challenge to vuex and redirect
            console.log(
              "Challenge inside: ",
              message.challenge["challenge-type"]
            );
            switch (message.challenge["challenge-type"]) {
              case "captcha":
                store.commit(`${flow}/setCurrentComponent`, PAGE.BankLogin);
                // AccountLinking.setCurrentComponent(PAGE.BankLogin);
                break;
              case "account-selection":
                store.commit(
                  `${flow}/setCurrentComponent`,
                  PAGE.AccountSelection
                );
                // AccountLinking.setCurrentComponent(PAGE.AccountSelection);
                break;
              case "otp":
                store.commit(`${flow}/setCurrentComponent`, PAGE.OTP);
                // AccountLinking.setCurrentComponent(PAGE.OTP);
                break;
              case "password":
                //absa surephrase
                store.commit(`${flow}/setCurrentComponent`, PAGE.Surephrase);
                // AccountLinking.setCurrentComponent(PAGE.Surephrase);
                break;
            }
          }
          break;
        case 100:
          // AccountLinking.setCurrentComponent(PAGE.Consent);
          break;
        case 300:
        case 500:
        case 1001:
        case 1000:
        case 1099: //Authentication completed
        case 1120: //Accounts added to collection
        case 1110: //Authorization required
          store.commit(`${flow}/setStatusMessage`, {
            title: "Authenticating and collecting your accounts",
            message:
              "We are verifying you credentials with your bank and collecting your account information.",
          });
          // AccountLinking.setStatusMessage({
          //   title: "Authenticating and collecting your accounts",
          //   message:
          //     "We are verifying you credentials with your bank and collecting your account information.",
          // });
          if (
            store.getters[`${flow}/getCurrentComponent`] !=
            PAGE.ProcessingStatus
          ) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.ProcessingStatus);
          }
          // if (AccountLinking.getCurrentComponent != PAGE.ProcessingStatus)
          //   AccountLinking.setCurrentComponent(PAGE.ProcessingStatus);
          break;
        case 1111: // Authorization received
        case 1200: // Download completed // Account 1 of 1 completed.
        case 1202:
        case 1203: // Successfully collected transaction history of
        case 1199:
        case 6000: // income-verification started // dashboard-aggregation started
        case 6001: // income-verification store to blob-storage succeed
        case 7000: // income-verification complete
        case 1299: // Requested data collected
        case 1021:
        case 1041:
          store.commit(`${flow}/setStatusMessage`, {
            title: "Loading your dashboard...",
            message:
              "We’re collecting your transaction data, calculating your affordability and creating your dashboard ",
          });
          // AccountLinking.setStatusMessage({
          //   title: "Loading your dashboard...",
          //   message:
          //     "We’re collecting your transaction data, calculating your affordability and creating your dashboard ",
          // });
          // if (AccountLinking.getCurrentComponent != PAGE.ProcessingStatus)
          //   AccountLinking.setCurrentComponent(PAGE.ProcessingStatus);
          // break;
          if (
            store.getters[`${flow}/getCurrentComponent`] !=
            PAGE.ProcessingStatus
          ) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.ProcessingStatus);
          }
          break;
        case 1040:
          store.commit(`${flow}/setStatusMessage`, {
            title: "Loading your dashboard...",
            message:
              "We’re collecting your transaction data, calculating your affordability and creating your dashboard ",
          });
          // AccountLinking.setStatusMessage({
          //   title: "Loading your dashboard...",
          //   message:
          //     "We’re collecting your transaction data, calculating your affordability and creating your dashboard ",
          // });
          if (
            store.getters[`${flow}/getCurrentComponent`] !=
            PAGE.ProcessingStatus
          ) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.ProcessingStatus);
          }
          setTimeout(() => {
            if (
              store.getters[`${flow}/getChallenge`] == null ||
              dayjs(store.getters[`${flow}/getChallenge`].timestamp).isBefore(
                message.timestamp
              )
            ) {
              if (message.challenge) {
                store.commit(`${flow}/setChallenge`, message.challenge);
              }
            }

            // if (
            //   AccountLinking.getChallenge == null ||
            //   dayjs(AccountLinking.getChallenge.timestamp).isBefore(
            //     message.timestamp
            //   )
            // ) {
            //   if (message.challenge) {
            //     AccountLinking.setChallenge(message.challenge);
            //   }
            // }
          }, 1000);

          break;
        case 1010:
          // Captcha required, go back to credentials to enter details and captcha (absa)
          // AccountLinking.setDisplayMessage(message.body.message);
          // AccountLinking.setBankLoginActive(true);
          break;
        case 1030:
          //capitec app, set component
          if (store.getters[`${flow}/getCurrentComponent`] != PAGE.MobileAuth) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.MobileAuth);
          }
          // if (AccountLinking.getCurrentComponent != PAGE.MobileAuth)
          //   AccountLinking.setCurrentComponent(PAGE.MobileAuth);
          break;
        case 1020:
          // two factor authentication required
          // AccountLinking.setDisplayMessage(message.body.message);
          // AccountLinking.setBankLoginActive(false);
          store.commit(`${flow}/setStatusMessage`, {
            title: "Authenticating and collecting your accounts",
            message:
              "We are verifying you credentials with your bank and collecting your account information.",
          });
          // AccountLinking.setStatusMessage({
          //   title: "Authenticating and collecting your accounts",
          //   message:
          //     "We are verifying you credentials with your bank and collecting your account information.",
          // });
          if (
            store.getters[`${flow}/getCurrentComponent`] !=
            PAGE.ProcessingStatus
          ) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.ProcessingStatus);
          }
          // if (AccountLinking.getCurrentComponent != PAGE.ProcessingStatus)
          //   AccountLinking.setCurrentComponent(PAGE.ProcessingStatus);
          break;
        case 1900:
          // go to timeout, change message
          break;
        case 2000: {
          //success, go to success and set milestone
          // AccountLinking.setDisplayMessage(message.body.message);

          if (flow == "AccountLinking") {
            if (store.getters[`${flow}/getCurrentComponent`] != PAGE.Overview) {
              store.commit(`${flow}/setCurrentComponent`, PAGE.Overview);
            }
          } else {
            Dashboard.setRefreshFlow({
              open: false,
              currentComp: null,
              refreshing: false,
            });
          }
          await store.dispatch(`${flow}/clear`, flow);
          await DashboardLib.getData();
          // if (AccountLinking.getCurrentComponent != PAGE.Overview)
          //   AccountLinking.setCurrentComponent(PAGE.Overview);
          break;
        }
        case 3000:
          // collection timeout, go to timeout and display message
          //   AccountLinking.setError({
          //     code: message.code,
          //     message: message.message,
          //   });
          if (store.getters[`${flow}/getCurrentComponent`] != PAGE.Timeout) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.Timeout);
          }
          // if (AccountLinking.getCurrentComponent != PAGE.Timeout)
          //   AccountLinking.setCurrentComponent(PAGE.Timeout);
          break;
        case 5000:
          //collection cancelled by user
          // AccountLinking.setCurrentComponent(PAGE.Cancelled);
          if (store.getters[`${flow}/getCurrentComponent`] != PAGE.Cancelled) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.Cancelled);
          }
          break;
        case 4000:
          //collection cancelled by system
          break;
        case 9001:
          //successfull but no data, go to timeout, change message
          // AccountLinking.setDisplayMessage(message.body.message);
          // AccountLinking.setCurrentComponent(PAGE.Error);
          await store.dispatch(`${flow}/clear`, flow);
          store.commit(`${flow}/setStatusMessage`, {
            title: "",
            message: message.code + ": " + message.message,
          });
          if (store.getters[`${flow}/getCurrentComponent`] != PAGE.Error) {
            store.commit(`${flow}/setCurrentComponent`, PAGE.Error);
          }
          //   AccountLinking.setError({
          //     code: message.code,
          //     message: message.message,
          //   });
          break;
        case 9000:
          // AccountLinking.setDisplayMessage(message.body.message);

          if (
            message.message
              .toLowerCase()
              .includes("login error: incorrect details") ||
            message.message
              .toLowerCase()
              .includes("login error: couldn't confirm your details") ||
            message.message
              .toLowerCase()
              .includes("invalid user credentials entered") ||
            message.message
              .toLowerCase()
              .includes("incorrect sign-in details") ||
            message.message.toLowerCase().includes("invalid credentials") ||
            message.message
              .toLowerCase()
              .includes(
                "login error: your login details have been entered incorrectly"
              )
          ) {
            // AccountLinking.setBankLoginActive(true);
            // User.setInvalidCredentials(true);
            if (store.getters[`${flow}/getSelectedDataProvider`] != null) {
              store.commit(`${flow}/setCurrentComponent`, PAGE.BankLogin);
            } else {
              store.commit(`${flow}/setCurrentComponent`, PAGE.BankSelection);
            }
            // if (AccountLinking.getSelectedDataProvider != null) {
            //   AccountLinking.setCurrentComponent(PAGE.BankLogin);
            // } else {
            //   AccountLinking.setCurrentComponent(PAGE.BankSelection);
            // }
          } else {
            // AccountLinking.setCurrentComponent(PAGE.Error);
            await store.dispatch(`${flow}/clear`, flow);
            store.commit(`${flow}/setStatusMessage`, {
              title: "",
              message: message.code + ": " + message.message,
            });
            store.commit(`${flow}/setCurrentComponent`, PAGE.Error);
          }
          // }
          break;
        default:
          //go to timeout, change message
          console.log("Skipped socket codes: ", message);
          // AccountLinking.setCurrentComponent(PAGE.Consent);
          break;
      }
    }
  };
  return {
    processTopicUpdate,
  };
};

const SocketLib = Socket();
export { SocketLib };
