
import { Component, Vue } from "vue-property-decorator";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import axios, { AxiosError } from "axios";
import AuthModule from "@/store/modules/Auth";

@Component
export default class OTP extends Vue {
  invalidOTP = false;
  get getOTPResponse() {
    return AuthModule.getOTPResponse;
  }
  otpInput: string | null = null;
  loading = false;
  get getValidOtp() {
    return this.otpInput != null && this.otpInput.length == 6;
  }
  get enteredEmail() {
    return AuthModule.getEnteredEmail;
  }
  mounted() {
    if (this.getOTPResponse == null) {
      this.goBack();
    }
  }
  goBack() {
    if (this.$route.query.from) {
      this.$router.push({
        name: this.$route.query.from as string,
      });
    } else {
      this.$router.push({
        name: "unauthed.landing",
      });
    }
  }
  async resendOtp() {
    try {
      this.loading = true;
      if (this.getOTPResponse != null) {
        console.log("Send this data: ", this.getOTPResponse);
        const res = await axiosInstance.get(
          `/consumers/${this.getOTPResponse["consumer-id"]}/otps/${this.getOTPResponse["otp-id"]}`
        );
        // AuthModule.setOTPResponse(res.data);
        console.log("OTP resend response -", res.data);
      }
    } catch (err) {
      console.log("Err -", err);
      if (axios.isAxiosError(err)) {
        if (err.response) {
          if (err.response.status == 400) {
            this.invalidOTP = true;
          }
        }
      }
    } finally {
      this.loading = false;
    }
  }
  async submitOTP() {
    try {
      this.loading = true;
      if (this.getOTPResponse != null && this.getValidOtp == true) {
        console.log("Send this data: ", this.getOTPResponse);
        const res = await axiosInstance.put(
          `/consumers/${this.getOTPResponse["consumer-id"]}/otps/${this.getOTPResponse["otp-id"]}`,
          {
            "otp-code": this.otpInput,
          }
        );
        if (res.headers.authorization) {
          console.warn("Had bearer ", res.headers.authorization);
        }
        AuthModule.setToken(res.headers.authorization.split("Bearer ")[1]);
        // AuthModule.setLocalStorage(
        //   res.headers.authorization.split("Bearer ")[1]
        // );
        console.log("OTP submit response -", res.data);
        this.$router.push({
          name: "dash.main",
        });
      }
    } catch (err) {
      console.log("Err -", err);
      if (axios.isAxiosError(err)) {
        if (err.response) {
          if (err.response.status == 400) {
            this.invalidOTP = true;
            await this.resendOtp();
          }
        }
      }
    } finally {
      this.loading = false;
    }
  }
  // onFinish() {
  //   //can submit automatically after they entered last input field
  //   console.log("submitting");
  // }
}
