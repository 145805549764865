var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"bg mt-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-6",attrs:{"cols":"11"}},[_c('v-row',{staticClass:"rounded white",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.openFilter = true}}},[_vm._v("Customise Offers "),_c('v-icon',[_vm._v("mdi-filter")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{staticClass:"textfield rounded-pill",attrs:{"outlined":"","append-outer-icon":"mdi-magnify","full-width":"","clearable":"","single-line":"","hide-details":"auto","label":"Search for provider name, or product keyword"}})],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},_vm._l((7),function(i){return _c('v-card',{key:i,staticClass:"mb-3",class:{
          'd-flex': _vm.$vuetify.breakpoint.xl,
        }},[_c('v-card-text',[_c('v-row',{staticClass:"pt-4",attrs:{"justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-avatar',{attrs:{"size":"95","color":"grey"}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"7"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-h3",attrs:{"cols":"12"}},[_c('div',[_vm._v("Finchoice")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h4"},[_vm._v(" FinChoice is an innovative digital financial service provider who specializes in fast loans, insurance and VAS products digitally to a growing base of customers in Africa. ")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto","offset":"2"}},[_c('v-chip',{attrs:{"color":"#ECDEFA"}},[_vm._v("Personal loans")]),_c('v-chip',{attrs:{"color":"#E2FADE"}},[_vm._v("Funeral cover")])],1)],1)],1),_c('v-card-actions',{staticClass:"mx-4 py-3",style:(_vm.$vuetify.breakpoint.xl
              ? 'border-top: 0'
              : 'border-top: 1px solid lightgrey')},[_c('v-row',{style:(_vm.$vuetify.breakpoint.xl
                ? 'border-left: 1px solid lightgrey'
                : 'border-left: 0'),attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" You have "),_c('strong',[_vm._v("95%")]),_vm._v(" chance of success ")])]),_c('v-col',{attrs:{"cols":"5","lg":"10"}},[_c('v-btn',{staticClass:"text-none",attrs:{"min-width":"300px","color":"primary","rounded":"","block":""}},[_vm._v("Apply now")])],1),_c('v-col',{attrs:{"cols":"5","lg":"10"}},[_c('v-btn',{staticClass:"text-none",attrs:{"text":"","color":"primary","rounded":""}},[_vm._v("Learn More")])],1)],1)],1)],1)}),1)],1),_c('v-dialog',{attrs:{"scrollable":""},model:{value:(_vm.openFilter),callback:function ($$v) {_vm.openFilter=$$v},expression:"openFilter"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticStyle:{"box-shadow":"0px 0px 16px rgba(0, 0, 0, 0.08)"}},[_c('div',{staticClass:"text-h3"},[_vm._v("Customise offers")])]),_c('v-card-text',{staticClass:"pt-3"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Product type")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h6"},[_vm._v("Select product type")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{staticClass:"textfield rounded-pill",attrs:{"height":55,"outlined":"","label":"Select Product Type","menu-props":{
                  offsetY: true,
                },"single-line":"","hide-details":"auto","validate-on-blur":"","required":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Credit amount")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" How much would you like to borrow? ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{staticClass:"textfield rounded-pill",attrs:{"height":55,"label":"Enter amount","outlined":"","single-line":"","hide-details":"auto","validate-on-blur":"","type":"text","clearable":"","required":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Period")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" How long do you want to borrow the amount for? ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{staticClass:"textfield rounded-pill",attrs:{"height":55,"outlined":"","label":"Select period","menu-props":{
                  offsetY: true,
                },"single-line":"","hide-details":"auto","validate-on-blur":"","required":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h5"},[_vm._v("Affordability")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" Verify your affordability based on your transactions ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{staticClass:"textfield rounded-pill mb-3",attrs:{"height":55,"label":"Net Income","outlined":"","single-line":"","hide-details":"auto","validate-on-blur":"","type":"text","clearable":"","required":""}}),_c('v-text-field',{staticClass:"textfield rounded-pill mb-3",attrs:{"height":55,"label":"Gross Income","outlined":"","single-line":"","hide-details":"auto","validate-on-blur":"","type":"text","clearable":"","required":""}}),_c('v-text-field',{staticClass:"textfield rounded-pill my-2",attrs:{"height":55,"label":"Total monthly expenses","outlined":"","single-line":"","hide-details":"auto","validate-on-blur":"","type":"text","clearable":"","required":""}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"primary"},[_c('v-btn',{attrs:{"color":"white"}},[_vm._v("Save and search")]),_c('v-btn',{attrs:{"color":"white","text":""}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }