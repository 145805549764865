import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { DASHBOARD_PAGE } from "@/models/nav";
import { lead, populateDashboardData, RefreshFlow } from "@/models/dashboard";
import { SocketTopicResponse } from "truid-common";
import Slider from "@/store/modules/Slider";
import dayjs from "dayjs";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Dashboard",
  store,
})
class Dashboard extends VuexModule {
  dashboard: null | populateDashboardData = null;
  leads: lead[] = [];
  loadingLeads = false;
  currentComponent: DASHBOARD_PAGE | null = null;
  refreshFlow: RefreshFlow = {
    open: false,
    currentComp: null,
    refreshing: false,
  };

  get getRefreshFlow() {
    return this.refreshFlow;
  }
  @Mutation
  setRefreshFlow(val: RefreshFlow) {
    this.refreshFlow = val;
  }

  get getLastStatus() {
    // if (this.currentComponent != null) {
    //   console.log("lol bs ");
    // }
    // if (store.hasModule(["Dashboard", "AccountLinking"])) {
    const temp: SocketTopicResponse | undefined =
      this.context.getters["AccountLinking/getLastStatus"];
    if (temp) return temp;

    // }

    return null;
  }
  get getCurrentComponent() {
    return this.currentComponent;
  }
  @Mutation
  setCurrentComponent(val: DASHBOARD_PAGE | null) {
    this.currentComponent = val;
  }
  get getAffordability() {
    if (this.getDashboardData) {
      if (this.getDashboardData["json-data"].cumulatives.affordability) {
        if (
          this.getDashboardData["json-data"].cumulatives.affordability.average >
          0
        )
          return this.getDashboardData["json-data"].cumulatives.affordability
            .average;
      }
    }
    return 0;
  }
  get getLeadsForAffordability() {
    return this.getLeads.filter((e) => {
      if (dayjs(e.expired).isBefore(dayjs()) == true) return false;
      if (e["max-instalments"] < Slider.getInstalments) return false;
      if (e["min-instalments"] > Slider.getInstalments) return false;
      if (
        e["min-affordability"] < Slider.getCurrentSpread &&
        e["max-affordability"] > Slider.getCurrentSpread
      ) {
        return true;
      }
      // }
      return false;
    });
  }
  get getLeads() {
    return this.leads;
  }
  @Mutation
  setLeads(val: lead[]) {
    this.leads = val;
  }
  get getLoadingLeads() {
    return this.loadingLeads;
  }
  @Mutation
  setLoadingLeads(val: boolean) {
    this.loadingLeads = val;
  }
  get getDashboardData() {
    return this.dashboard;
  }
  @Mutation
  setDashboardData(val: populateDashboardData | null) {
    this.dashboard = val;
  }
  @Action
  async clear() {
    //clear sockets.
    console.log("Clear dashboard");
    if (store.hasModule(["Dashboard", "AccountLinking"])) {
      console.log("Clear dashboard inner");
      this.context.dispatch("AccountLinking/clear", "Dashboard/AccountLinking");
    }

    this.setCurrentComponent(null);
    this.setDashboardData(null);
    this.setLoadingLeads(false);
    this.setLeads([]);
  }
}
export default getModule(Dashboard);
