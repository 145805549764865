
import { RefreshFlow } from "@/models/dashboard";
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

@Component
export default class RefreshModal extends Vue {
  @PropSync("refreshFlow") current!: RefreshFlow;
  @Prop() refreshing!: boolean;
  cancel() {
    this.current.open = false;
    this.current.currentComp = null;
  }
  async refresh() {
    // create collection -> populate data
    // load collection
    // mounted websocket
    // process last status / check preselected bank / accounts
    // await DashboardLib.populateData("Dashboard/AccountLinking");
    // await CollectionLib.loadCollection("Dashboard/AccountLinking");
    this.$emit("refresh", true);
  }
}
