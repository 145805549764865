
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import NavModule from "@/store/modules/Nav";
import CompleteYourProfile from "@/components/CompleteProfile/CompleteYourProfile.vue";

@Component({
  components: {
    CompleteYourProfile,
  },
})
export default class NavDrawer extends Vue {
  async logout() {
    NavModule.setLogoutPrompt(true);
  }
  get getAppVersion() {
    return process.env.VUE_APP_VERSION;
  }
  // navDrawer = true;
  get navDrawer() {
    return NavModule.getAppDrawer;
  }
  set navDrawer(val: boolean) {
    NavModule.setAppDrawer(val);
  }
  menu = [
    {
      icon: "mdi-view-dashboard",
      link: "dash.main",
      text: "Dashboard",
    },
    // {
    //   icon: "mdi-credit-card-check-outline",
    //   link: "dash.main",
    //   text: "Get Credit",
    // },
    // {
    //   icon: "mdi-store",
    //   link: "dash.service-providers",
    //   text: "Service Providers",
    // },
    {
      icon: "mdi-check-decagram",
      link: "dash.profile",
      text: "Profile",
    },
    {
      icon: "mdi-lifebuoy",
      link: "dash.help",
      text: "Help",
    },
  ];
}
