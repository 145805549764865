export enum PAGE {
  "Timeout" = "Timeout",
  "Error" = "Error",
  "GenerateDashboard" = "GenerateDashboard",
  "GetStarted" = "GetStarted",
  "Overview" = "Overview",
  "Consent" = "Consent",
  "StoredConsents" = "StoredConsents",
  "Cancelled" = "Cancelled",
  "SelectAccounts" = "SelectAccounts",
  "Polling" = "Polling",
  "RetrieveAccounts" = "RetrieveAccounts",
  "VerificationSuccessful" = "VerificationSuccessful",
  "BankSelection" = "BankSelection",
  "AbsaSurephrase" = "AbsaSurephrase",
  "BankLogin" = "BankLogin",
  "OTP" = "OTP",
  "MobileAuth" = "MobileAuth",
  "Surephrase" = "Surephrase",
  "BankAppAuth" = "BankAppAuth",
  "Processing" = "Processing",
  "ProcessingStatus" = "ProcessingStatus",
  "AccountSelection" = "AccountSelection",
}

export enum DASHBOARD_PAGE {
  "TwoFactor" = "TwoFactor",
  "Consent" = "Consent",
  "Login" = "Login",
  "DeleteAccount" = "DeleteAccount",
  "Refresh" = "Refresh",
  "Error" = "Error",
}

export enum TypeOfUser {
  None,
  First,
  Recurring,
}

export type SocketFlow = "Dashboard/AccountLinking" | "AccountLinking";
