import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { CURRENT_USER, LoginResponse } from "@/models/user";
import { IStompSocket } from "@stomp/stompjs";
// import * as Test from "truid-common";
import { BRAND, Collection, SocketChallenge } from "truid-common";

@Module({
  dynamic: true,
  namespaced: true,
  name: "User",
  store,
})
class User extends VuexModule {
  updatePersonalDetails = false;
  verifyIdentity = false;
  verifyIncome = false;
  // challengeID: string | null = null;
  // challenge: SocketChallenge | null = null;
  // collectionId = "";
  // collection: Collection | null = null;

  brand: BRAND | null = null;
  get isPersonalDetailsOpen() {
    return this.updatePersonalDetails;
  }
  @Mutation
  setPersonalDetailsOpen(val: boolean) {
    this.updatePersonalDetails = val;
  }
  get isVerifyIdentityOpen() {
    return this.verifyIdentity;
  }
  @Mutation
  setVerifyIdentity(val: boolean) {
    this.verifyIdentity = val;
  }
  get isVerifyIncomeOpen() {
    return this.verifyIncome;
  }
  @Mutation
  setVerifyIncome(val: boolean) {
    this.verifyIncome = val;
  }
  get getBrand(): BRAND | null {
    return this.brand;
  }
  @Mutation
  setBrand(val: any | null) {
    this.brand = val;
  }
  // get getCollection(): Collection | null {
  //   return this.collection;
  // }
  // @Mutation
  // setCollection(val: Collection | null) {
  //   this.collection = val;
  // }
  // get getCollectionID() {
  //   return this.collectionId;
  // }
  // @Mutation
  // setCollectionID(val: string) {
  //   this.collectionId = val;
  // }
  // get getChallengeID(): string | null {
  //   return this.challengeID;
  // }
  // @Mutation
  // setChallengeID(val: string | null) {
  //   this.challengeID = val;
  // }
  // get getChallenge(): SocketChallenge | null {
  //   return this.challenge;
  // }
  // @Mutation
  // setChallenge(val: SocketChallenge | null) {
  //   this.challenge = val;
  // }
}
export default getModule(User);
