
import { Component, Vue, Prop } from "vue-property-decorator";
import NavModule from "@/store/modules/Nav";
import { dataProvidersLib } from "@/helpers/dataProviders";
import DataProvidersModule from "@/store/modules/DataProviders";
import { DataProvider } from "@/models/dataProviders";
import { PAGE } from "@/models/nav";

@Component
export default class BankSelection extends Vue {
  @Prop() formattedTime!: string;
}
