
import { Component, Vue, PropSync } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import NavModule from "@/store/modules/Nav";
import store from "@/store";

@Component
export default class LogoutPrompt extends Vue {
  get isOpen() {
    return NavModule.getLogoutPrompt;
  }
  set isOpen(val: boolean) {
    NavModule.setLogoutPrompt(val);
  }

  async logout() {
    console.log("Logout");
    this.isOpen = false;
    AuthModule.logout();
    this.$router.push({
      name: "unauthed.landing",
    });
  }
}
