
import { DashboardLib, FieldValidationLib, SocketLib } from "@/helpers";
import { Component, Vue, Watch } from "vue-property-decorator";
import CompleteYourProfile from "@/components/CompleteProfile/CompleteYourProfile.vue";
import User from "@/store/modules/User";
import Auth from "@/store/modules/Auth";
import Dashboard from "@/store/modules/Dashboard";
import store from "@/store";

@Component({
  components: {
    CompleteYourProfile,
  },
  metaInfo() {
    return {
      title: "Settings",
    };
  },
})
export default class Profile extends Vue {
  currentTab = "profile";
  e6 = 1;
  firstName: string | null = null;
  lastName: string | null = null;
  email: string | null = null;
  async mounted() {
    if (Auth.getTokenInfo && Auth.getTokenInfo.exists == "true") {
      await DashboardLib.getData();
    }
  }
  async activated() {
    if (Auth.getTokenInfo && Auth.getTokenInfo.exists == "true") {
      await DashboardLib.getData();
    }
  }
  get incomeDetails() {
    return User.isVerifyIncomeOpen;
  }
  set incomeDetails(val: boolean) {
    User.setVerifyIncome(val);
  }
  get personalDetails() {
    return User.isPersonalDetailsOpen;
  }
  set personalDetails(val: boolean) {
    User.setPersonalDetailsOpen(val);
  }
  get getBankingDetails() {
    if (Dashboard.getDashboardData) {
      const temp = {
        account: Dashboard.getDashboardData.account,
        "data-provider": Dashboard.getDashboardData["data-provider"],
      };
      return temp;
    }
    return null;
  }
  get getInfoFromToken() {
    return Auth.getTokenInfo;
  }
  get getID() {
    if (Dashboard.getDashboardData == null) return null;
    return Dashboard.getDashboardData["masked-id-number"];
  }
  get getRules() {
    return FieldValidationLib.rules;
  }
  get getLastStatusDashboard() {
    return Dashboard.getLastStatus;
  }
  goBack() {
    this.$router.push({
      name: "Main",
    });
  }
  @Watch("getLastStatusDashboard", { deep: true })
  async dashStatusChanged(val: any) {
    console.log("Testing this watcher ", val);
    if (val) {
      await SocketLib.processTopicUpdate(val, "Dashboard/AccountLinking");
    }
  }
  triggerRefreshProcess() {
    Dashboard.setRefreshFlow({
      open: true,
      currentComp: "AccountLinking",
      refreshing: false,
    });
  }
}
