
import store from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Unauthenticated extends Vue {
  mounted() {
    // if (store.hasModule("Dashboard")) {
    //   store.unregisterModule("Dashboard");
    // }
    // if (store.hasModule("AccountLinking")) {
    //   store.unregisterModule("AccountLinking");
    // }
  }
}
