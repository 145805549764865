
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import NavModule from "@/store/modules/Nav";

@Component
export default class AppBar extends Vue {
  get navDrawer() {
    return NavModule.getAppDrawer;
  }
  set navDrawer(val: boolean) {
    NavModule.setAppDrawer(val);
  }
}
