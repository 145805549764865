
import { Component, Vue, PropSync } from "vue-property-decorator";
import { axiosNoAuth } from "@/plugins/axios";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import axios, { AxiosError } from "axios";
import { FieldValidationLib } from "@/helpers";
import TermsText from "@/components/TermsText.vue";

@Component({
  components: {
    TermsText,
  },
})
export default class TermsAndConditionsModal extends Vue {
  @PropSync("terms") tcs!: boolean;
  loading = false;
  email: string | null = null;
  valid = false;
  error = {
    credentials: false,
    general: false,
  };

  get getRules() {
    return FieldValidationLib.rules;
  }
  onScroll() {
    console.log("Trust");
  }
  async submitCredentials(e: any): Promise<void> {
    // console.log("Is this form", e);
    try {
      this.loading = true;
      if (this.email != null) {
        // const res = await UserLib.login({
        //   username: this.username,
        //   password: this.password,
        // });
        // const temp = {
        //   ...res,
        //   createdAt: dayjs().toISOString(),
        // };
        // AuthModule.setLoginResponse(temp);
        // await StorageLib.setLogin(temp);
        // const resMe = await UserLib.getMe();
        // AuthModule.setUser(resMe
        return Promise.resolve();
      }
    } catch (err) {
      console.log("Error login: ", err);
      if (axios.isAxiosError(err) == true) {
        const temp = err as AxiosError;
        if (temp.response && temp.response.status === 401) {
          this.error.credentials = true;
        } else {
          this.error.general = true;
        }
      }
    } finally {
      this.loading = false;
    }
  }
}
