
import DashboardModule from "@/store/modules/Dashboard";
import { Component, Vue } from "vue-property-decorator";
import Offer from "./Offer.vue";

@Component({
  components: {
    Offer,
  },
})
export default class Offers extends Vue {
  offers = 0;
  get getLeadsForAffordability() {
    return DashboardModule.getLeadsForAffordability;
    // return DashboardModule.getLeads.filter((e) => {
    //   if (dayjs(e.expired).isBefore(dayjs()) == true) return false;
    //   if (e["min-instalments"] < Slider.getInstalments) return false;
    //   if (Slider.getInstalments <= 1) {
    //     if (e["min-loan-amount"] < Slider.getCurrentSpread) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
  }
  get getLastRefreshDate() {
    return DashboardModule.getDashboardData?.date;
  }
}
