
import { Component, Vue, Prop } from "vue-property-decorator";
import { PAGE, SocketFlow } from "@/models/nav";
import ErrorCard from "./ErrorCard.vue";
import store from "@/store";

@Component({
  components: {
    ErrorCard,
  },
})
export default class Error extends Vue {
  @Prop() flow!: SocketFlow;

  close() {
    console.log("Closing from error");
    this.$emit("close");
  }
  // backToConsent() {
  //   // should retry
  //   if (this.flow == "AccountLinking") {
  //     store.commit(`${this.flow}/setCurrentComponent`, PAGE.GetStarted);
  //   } else {
  //     // store.commit(`${this.flow}/setCurrentComponent`, PAGE.Consent);
  //   }
  // }
}
