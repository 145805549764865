
import { CollectionLib, DashboardLib } from "@/helpers";
import { lead, RefreshFlow } from "@/models/dashboard";
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

@Component
export default class ConsentModal extends Vue {
  @PropSync("refreshFlow") obj!: RefreshFlow;
  loading = false;
  cancel() {
    this.obj.open = false;
    this.obj.currentComp = null;
  }
  demo() {
    this.obj.currentComp = "Login";
  }
  async followLead() {
    try {
      this.loading = true;
      if (this.obj.lead) await CollectionLib.followLead(this.obj.lead);
      await DashboardLib.getData();
      this.obj.open = false;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
