
import { Component, Vue } from "vue-property-decorator";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import axios, { AxiosError } from "axios";
import { FieldValidationLib } from "@/helpers";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class Login extends Vue {
  loading = false;
  email: string | null = null;
  valid = false;
  captcha = false;
  get getSiteKey() {
    return process.env.VUE_APP_RECAPTCHA;
  }
  error: {
    code: number | null;
    message: string | null;
    credentials: boolean;
    notFound: boolean;
    general: boolean;
  } = {
    code: null,
    message: null,
    credentials: false,
    notFound: false,
    general: false,
  };
  verifyCaptcha(val: any) {
    console.log("Verify captcha", val);
    this.captcha = true;
  }
  errorCaptcha(val: any) {
    console.log("ERror captcha ", val);
    this.captcha = false;
  }
  expiredCaptcha(val: any) {
    console.log("Expired captcha ", val);
    this.captcha = false;
  }
  get getValid() {
    return (
      this.email != null &&
      this.getRules.email(this.email) == true &&
      this.captcha == true
    );
  }
  get getRules() {
    return FieldValidationLib.rules;
  }
  async submitCredentials(e: any): Promise<void> {
    // console.log("Is this form", e);
    try {
      this.loading = true;
      if (this.email != null) {
        this.error = {
          code: null,
          message: null,
          general: false,
          notFound: false,
          credentials: false,
        };
        const res = await axiosInstance.post("/login", {
          email: this.email.toLowerCase(),
        });
        // const temp = {
        //   ...res,
        //   createdAt: dayjs().toISOString(),
        // };
        AuthModule.setEnteredEmail(this.email);
        AuthModule.setOTPResponse(res.data);
        this.$router.push({
          name: "otp",
          query: {
            from: "Sign In",
          },
        });
        // AuthModule.setLocalStorage(res.data);
        // await StorageLib.setLogin(temp);
        // const resMe = await UserLib.getMe();
        // AuthModule.setUser(resMe
        return Promise.resolve();
      }
    } catch (err) {
      console.log("Error login: ", err);
      if (axios.isAxiosError(err) == true) {
        const temp = err as AxiosError;
        if (temp.response) {
          this.error.code = temp.response.status;
          if (temp.response.data) {
            const mess = (temp.response.data as any).message;
            if (mess) this.error.message = mess;
          }
          if (temp.response.status === 401) {
            this.error.credentials = true;
            this.error.message = "Unauthorized";
          } else if (temp.response.status === 404) {
            this.error.notFound = true;
          }
        } else {
          this.error.general = true;
        }
      }
    } finally {
      this.loading = false;
    }
  }
}
