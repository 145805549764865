
import { Component, Vue, Prop } from "vue-property-decorator";
import Timer from "@/components/Timer.vue";
import { SocketFlow } from "@/models/nav";
import SurephraseCard from "./SurephraseCard.vue";

@Component({
  components: {
    Timer,
    SurephraseCard,
  },
})
export default class Surephrase extends Vue {
  @Prop() flow!: SocketFlow;

  goBack() {
    console.log("Go back? ");
  }
}
