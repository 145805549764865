
import { Component, Vue, Prop } from "vue-property-decorator";
import OTPCard from "./OTPCard.vue";
import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    OTPCard,
  },
})
export default class OTP extends Vue {
  @Prop() flow!: SocketFlow;

  goBack() {
    // cancel web socket flow -> Modal for cancel
  }
}
