
import { Component, Vue, Prop } from "vue-property-decorator";
import { CollectionLib } from "@/helpers";
import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";

@Component
export default class ConsentCard extends Vue {
  @Prop() flow!: SocketFlow;
  loading = false;
  get collectionId() {
    return store.getters[`${this.flow}/getCollectionID`];
  }
  goBack() {
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.GetStarted);
  }
  async consent() {
    try {
      this.loading = true;
      await CollectionLib.loadCollection(this.flow);
      store.commit(`${this.flow}/setCurrentComponent`, PAGE.BankSelection);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
