import NotificationSystem from "@/store/modules/NotificationSystem";
import { notificationType } from "@/models/dashboard";

const delay = 100;

const Notifications = () => {
  const createAlarmNotification = (notif: {
    title: string | undefined;
    body: string | undefined;
    id: string;
    cb: () => void;
  }): notificationType => {
    const temp = {
      color: "warning",
      title: notif.title,
      message: notif.body != undefined ? notif.body : "Message not loaded",
      icon: "mdi-alarm",
      timeout: 10000,
      id: notif.id,
      cb: notif.cb,
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createSuccessNotification = (message: string): notificationType => {
    const temp = {
      color: "success",
      message: message,
      icon: "mdi-check-all",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000).toString(),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createErrorNotification = (message: string): notificationType => {
    const temp = {
      color: "error",
      message: message,
      icon: "mdi-alert-circle",
      timeout: 5000,
      id: Math.ceil(Math.random() * 1000).toString(),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createWarningNotification = (message: string): notificationType => {
    const temp = {
      color: "warning",
      message: message,
      icon: "mdi-shield-alert",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000).toString(),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  return {
    createSuccessNotification,
    createWarningNotification,
    createErrorNotification,
    createAlarmNotification,
  };
};
const notifications = Notifications();
export { notifications };
