const FieldValidation = () => {
  const rules = {
    required: (v: string) => !!v || "Cannot be empty",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Requires atleast 1 UPPERCASE character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Requires atleast 1 NUMBER character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Requires atleast 1 lowercase character";
    },
    email: (v: string) => {
      const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Valid email required";
    },
    numbers: (v: string) => /^[0-9]/.test(v) || "Numbers only",
  };
  const fakeFunction = async () => {
    //just a demo.
  };
  return {
    rules,
  };
};
const FieldValidationLib = FieldValidation();
export { FieldValidationLib };
