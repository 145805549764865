
import { Component, Vue, Watch } from "vue-property-decorator";
// import * as images from "../../assets/landing/serviceProviders/*.png";
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return Object.values(images);
}
const images = importAll(
  require.context("@/assets/landing/serviceProviders", false, /\.(png)$/)
);
console.log("Images ", images);

@Component
export default class Landing extends Vue {
  partnerSlide = 0;
  partners = images;
  partnerInterval = 0;
  email: string | null = null;
  goToSignIn() {
    this.$router.push({
      name: "signin",
    });
  }
  created() {
    this.nextPartner();
  }
  beforeDestroy() {
    clearInterval(this.partnerInterval);
  }
  prevPartner() {
    clearInterval(this.partnerInterval);
    this.prev();
    this.partnerInterval = setInterval(() => {
      this.next();
    }, 2000);
  }
  next() {
    const nextAmount = this.$vuetify.breakpoint.smAndDown
      ? 1
      : Math.floor(this.partners.length / 2);
    console.log("Next ", this.$vuetify.breakpoint.smAndDown);
    if (this.partnerSlide + nextAmount >= this.partners.length - 1) {
      console.log("loop next");
      if (this.partnerSlide + nextAmount - (this.partners.length - 1) > 3) {
        console.log("loop next to front ");
        this.partnerSlide = 0;
      } else {
        this.partnerSlide = this.partners.length - 1;
      }
    } else {
      this.partnerSlide += nextAmount;
    }
  }
  prev() {
    console.log("prev", this.partnerSlide);
    if (this.partnerSlide == 0) {
      console.log("loop prev");
      this.partnerSlide = this.partners.length - 1;
    } else {
      this.partnerSlide--;
    }
  }
  nextPartner() {
    clearInterval(this.partnerInterval);
    this.next();
    this.partnerInterval = setInterval(() => {
      this.next();
    }, 3000);
  }
  goToSignUp() {
    let path: any = {
      name: "signup",
    };
    if (this.email) {
      path = {
        ...path,
        query: {
          email: this.email,
        },
      };
    }
    this.$router.push(path);
  }
}
