
import { lead } from "@/models/dashboard";
import DashboardModule from "@/store/modules/Dashboard";
import dayjs from "dayjs";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Offers extends Vue {
  @Prop() offer!: lead;
  triggeredRefresh = false;

  get refreshFlow() {
    return DashboardModule.getRefreshFlow;
  }
  set refreshFlow(val) {
    DashboardModule.setRefreshFlow(val);
  }

  get comingSoon() {
    const pub = dayjs(this.offer.published);
    const today = dayjs(dayjs().format("YYYY-MM-DD"));
    return today < pub;
  }
  get getLastStatus() {
    return DashboardModule.getLastStatus;
  }
  followLead() {
    // console.log("Popup flow", this.offer);
    if (!this.getExpiryDate) {
      alert("No date to check");
      return;
    }
    if (dayjs() > dayjs(this.getExpiryDate, "DD-MMM-YYYY")) {
      alert("Need to refresh before applying");
      this.refreshFlow.currentComp = "Refresh";
      this.refreshFlow.open = true;
    } else {
      this.refreshFlow.currentComp = "ConsentModal";
      this.refreshFlow.lead = this.offer;
      this.refreshFlow.open = true;
    }
  }
  get getExpiryDate() {
    return DashboardModule.getDashboardData?.expires;
  }
}
