
import { Component, Vue, Prop } from "vue-property-decorator";
import Timer from "@/components/Timer.vue";
import dayjs from "dayjs";

import {
  DetailsOTP,
  SocketChallenge,
  SocketPayload,
  SocketPayloadChallenge,
  StompClient,
} from "truid-common";
import store from "@/store";
import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    Timer,
  },
})
export default class OTPCard extends Vue {
  @Prop() flow!: SocketFlow;
  valid = false;

  timeLimit = 150;
  timerInterval: number | null = null;
  timeStarted: number | null = null;
  currentTime = dayjs().valueOf();
  otp: string | null = null;
  get getCollectionID(): string | null {
    if (store.getters[`${this.flow}/getCollectionID`])
      return store.getters[`${this.flow}/getCollectionID`];
    else return null;
  }
  get getCollection() {
    if (store.getters[`${this.flow}/getCollection`])
      return store.getters[`${this.flow}/getCollection`];
    else return null;
  }
  submitChallenge() {
    console.log("Submit challenge");
    if (
      this.getChallenge &&
      this.getCollectionID &&
      this.getCollection &&
      this.otp != null
    ) {
      const temp: SocketPayloadChallenge = {
        "challenge-id": this.getChallenge.id,
        // response: Array.from(this.otp),
        response: [this.otp],
      };
      const payload: SocketPayload = {
        name: "challenge-response",
        "collection-id": this.getCollectionID,
        "wss-token": this.getCollection["wss-token"],
        payload: temp,
      };
      // stompClient.send(payload);
      const st: StompClient | null = store.getters[`${this.flow}/getStomp`];
      st?.send(payload);
      store.commit(`${this.flow}/setChallenge`, null);
      store.commit(`${this.flow}/setChallengeID`, null);
      // User.setChallenge(null);
      // User.setChallengeID(null);
      // NavModule.setCurrentComponent(PAGE.Processing);
    }
  }

  get getValid() {
    if (
      this.otp == null ||
      this.getChallengeDetails == null ||
      this.timeLeft <= 0
    )
      return false;
    return this.otp.length == this.getChallengeDetails.length;
  }

  get rules() {
    return {
      required: (value: string | null) => {
        if (value == null || value == undefined || value.length == 0) {
          return "Required";
        } else return true;
      },
      // (value != null && value != undefined) || "Required.",
      counter: (value: string) => {
        if (value != null) {
          return value.length == 4 || "Requires 4 characters";
        } else return "Required";
      },

      // value.length == this.getChallengeOTP.details.length ||
      // `${this.getChallengeOTP.details.length} characters required`,

      // type: (value: string) => {
      //   if (this.getChallengeOTP.details.type == "numeric") {
      //     const pattern = /^[0-9]+$/;
      //     return (
      //       pattern.test(value) ||
      //       `${this.getChallengeOTP.details.type} characters Required`
      //     );
      //   }
      //   return true;
      // },
    };
  }
  // toggleCancel() {
  //   NavModule.setCancelActive(true);
  // }
  get otpRules() {
    // return [this.rules.required, this.rules.type, this.rules.counter];
    return [this.rules.required, this.rules.counter];
  }
  mounted() {
    this.checkTimeOnMounted();
    this.startTimer();
  }
  startTimer() {
    this.timerInterval = setInterval(
      () => (this.currentTime = dayjs().valueOf()),
      1000
    );
  }
  requestNewOtp() {
    console.log("Request new otp here");
  }
  onTimesUp() {
    if (this.timerInterval != null) clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.$emit("error", "Timed out waiting for user input.");
  }
  restartTimer() {
    if (this.timerInterval != null) clearInterval(this.timerInterval);
    this.checkTimeOnMounted();
    this.startTimer();
  }
  submitOTP() {
    console.log("Submit OTP");
    // NavModule.setCurrentComponent(PAGE.AccountSelection);
  }
  goBack() {
    // cancel web socket flow -> Modal for cancel
  }
  get getChallengeDetails() {
    if (this.getChallenge) {
      return this.getChallenge.details as DetailsOTP;
    }
    return null;
  }
  get getChallenge(): SocketChallenge | null {
    if (store.getters[`${this.flow}/getChallenge`]) {
      return store.getters[`${this.flow}/getChallenge`];
    }
    return null;
  }
  checkTimeOnMounted() {
    if (this.getChallenge) {
      this.timeStarted = dayjs(this.getChallenge.timestamp).toDate().valueOf();
      this.timeLimit = this.getChallenge.timeout / 1000;
    }
  }

  get getSelectedBank() {
    // return DataProvidersModule.getSelectedDataProvider;
    return store.getters[`${this.flow}/getSelectedDataProvider`];
  }
  get formattedTimeLeft() {
    const timeLeft = this.timeLeft;
    const minutes = Math.floor(this.timeLeft / 60);
    let seconds = (timeLeft % 60).toString();

    if (timeLeft % 60 < 10) {
      seconds = `0${timeLeft % 60}`;
    }

    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds}`;
  }
  get timeLeft() {
    if (this.timeStarted != null) {
      const temp =
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000);
      return temp >= 0 ? temp : 0;
    }
    return 0;
  }
}
