import _Vue from "vue";
import axios from "axios";
import Auth from "@/store/modules/Auth";
import { NotificationLib } from "@/helpers";
import router from "@/router";
const axiosInternal = axios.create({
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const axiosNoAuth = axios.create({
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
});
const axiosOldInstance = axios.create({
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": process.env.VUE_APP_API_KEY_OLD,
  },
  baseURL: process.env.VUE_APP_BASE_OLD_API_URL,
});
const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": process.env.VUE_APP_API_KEY,
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
});
axiosInstance.interceptors.response.use((response) => {
  if (response.headers.authorization) {
    const temp = response.headers.authorization.split("Bearer ")[1];
    if (temp != Auth.getToken) {
      const consumer = JSON.parse(atob(temp.split(".")[1]));
      Auth.setToken(temp);
      Auth.setLocalStorage(temp);
      Auth.setOTPResponse({
        "consumer-id": consumer.sub,
      });
    }
  }
  return response;
});
axiosInstance.interceptors.request.use((restConfig) => {
  const u = Auth.getToken;
  if (u != null) {
    const config = restConfig;
    if (config.headers) {
      config.headers.Authorization = "Bearer " + u;
    }

    return config;
  }
  return restConfig;
});

const generateMessageFromError = (error: unknown): string => {
  let message = "";
  if (axios.isAxiosError(error)) {
    console.log("To json response ", error.toJSON(), error);
    if (error.response) {
      if (error.response.data && error.response.data != "") {
        // for our BE
        if (
          (error.response.data as any).status &&
          (error.response.data as any).title
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).title + `<br />`;
          const errs = (error.response.data as any).errors;
          if (errs) {
            Object.entries(errs).forEach((e) => {
              message += e[1] + `<br />`;
            });
          }
        } else if (
          (error.response.data as any).status &&
          (error.response.data as any).message
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).message;
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.statusText) {
        // general error message
        console.log("data if else ", error.response);
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else {
        // root error
        console.log("data else ", error);
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      console.log("Hello logger ", error);
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    if (error instanceof Error) {
      if (error.message) {
        message = error.message;
      } else {
        console.error("error non message", error);
      }
    } else if (typeof error === "object") {
      const temp = JSON.stringify(error);
      if (temp) {
        message = temp;
      } else {
        console.error("Couldnt stringify error", error);
        // message = error;
      }
    } else {
      message = "Unidentified error: " + error;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.warn(error);
    const message = generateMessageFromError(error);
    console.log("Testing message :", message);
    NotificationLib.createErrorNotification(message);
    if (axios.isAxiosError(error)) {
      console.log("Axios inter: ", error);
      if (error.response) {
        if (error.response.status == 401 || error.response.status == 403) {
          Auth.logout();
          router.push({
            name: "unauthed.landing",
          });
        }
        // Auth.setToken(null);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosNoAuth, axiosInstance, axiosOldInstance, axiosInternal };

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$axiosInstance = axiosInstance;
  },
};
