
import { Component, Vue, Prop } from "vue-property-decorator";
import AccountLinking from "@/store/modules/AccountLinking";
import CompleteYourProfile from "@/components/CompleteProfile/CompleteYourProfile.vue";
import { PAGE, SocketFlow } from "@/models/nav";
import { SocketModule } from "truid-common";
import store from "@/store";

@Component
export default class Error extends Vue {
  @Prop() flow!: SocketFlow;
  viewError = false;
  // retry() {
  //   if ()
  // }
  async backToConsent() {
    // should retry
    // if (this.flow == "AccountLinking") {
    await store.dispatch(`${this.flow}/clear`, this.flow);
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.GetStarted);
    // } else {
    //   this.$emit("close");
    //   // store.commit(`${this.flow}/setCurrentComponent`, PAGE.Consent);
    // }
  }
  get getCollectionID() {
    // return SocketModule.getLastStatus;
    return store.getters[`${this.flow}/getCollectionID`];
  }
  get getLastStatus() {
    // return SocketModule.getLastStatus;
    return store.getters[`${this.flow}/Socket/getLastStatus`];
  }
  get getACMessage() {
    // return SocketModule.getLastStatus;
    return store.getters[`${this.flow}/getStatusMessage`];
  }
}
