import UserModule from "@/store/modules/User";
import store from "@/store";
import NavModule from "@/store/modules/Nav";
import { axiosInstance } from "@/plugins/axios";
import { StompClient } from "truid-common";
import DataProviders from "@/store/modules/DataProviders";
import { SocketLib } from "./socket";
import AccountLinking from "@/store/modules/AccountLinking";
// import { PAGE, TypeOfUser } from "@/models/user";
// import { MILESTONE } from "@/models/socket";

import { SocketModule } from "truid-common";
import { lead } from "@/models/dashboard";
import Auth from "@/store/modules/Auth";
import Dashboard from "@/store/modules/Dashboard";
import { SocketFlow } from "@/models/nav";

const Collections = () => {
  const followLead = async (lead: lead) => {
    if (Auth.getOTPResponse == null) return Promise.resolve();
    const res = await axiosInstance.post(
      "/consumers/" + Auth.getOTPResponse["consumer-id"] + "/leads",
      {
        "lead-id": lead.id,
      }
    );
    console.log("Follow lead response : ", lead, res);
    if (res.headers.location) {
      const replaced = lead["redirect-url"].replace(
        "{collection-id}",
        res.headers.location
      );
      window.open(replaced);
    }

    //get transaction from response
    // if (Auth.getOTPResponse) {
    //   const replaced = lead["redirect-url"].replace(
    //     "{collection-id}",
    //     Auth.getOTPResponse["consumer-id"]
    //   );
    //   window.open(replaced);
    // }
    return Promise.resolve();

    // attach to lead redirect url and redirect
    // window.open(lead.redirectUrl)
  };
  const setup = async (flow: SocketFlow) => {
    const socketName = `${flow}/Socket`;
    console.log("Socket setup", flow);
    if (
      store.getters[`${flow}/getCollectionID`] != null &&
      store.getters[`${flow}/getCollection`]
    ) {
      const split = flow.split("/");
      split.push("Socket");
      console.log("Current State : ", `${flow}/getCurrentComponent`, split);
      if (store.hasModule(socketName)) {
        // deregister reregister components for new collection id
        console.log(
          "Has account linking socket",
          store.getters[`${socketName}/getConnectedCollection`]
        );
      } else {
        // if (flow == "Dashboard/AccountLinking") {
        //   // mount account Linking
        //   store.registerModule(["Dashboard", "AccountLinking"], AccMod);
        // }
        // const temp
        store.registerModule(split, SocketModule);
        console.log("after Current State : ", store.state, store);
      }
      const stomp = new StompClient(socketName);
      store.commit(`${flow}/setStomp`, stomp);
      const client = stomp.createClient(
        store.getters[`${flow}/getCollectionID`]
      );
      const col = store.getters[`${flow}/getCollection`];
      console.warn("Test socket process ", col);
      if (col) {
        if (flow == "AccountLinking") {
          await SocketLib.processTopicUpdate(col["status-dto"], flow);
        } else {
          await SocketLib.processTopicUpdate(col["status-dto"], flow);
        }
      }

      console.log("Should be stored to vuex? ", client);
    }
  };
  const login = async (
    body: {
      [key: string]: any;
    },
    flow: SocketFlow
  ) => {
    const res = await axiosInstance
      .post(
        "/collections/" +
          store.getters[`${flow}/getCollectionID`] +
          "/credentials",
        {
          provider: store.getters[`${flow}/getSelectedDataProvider`]?.id,
          ...body,
        }
      )
      .catch((err) => {
        console.error("Error logging in: ", err);
        return Promise.reject(err);
      });
  };
  const loadCollection = async (flow: SocketFlow) => {
    const id = store.getters[`${flow}/getCollectionID`];
    if (id) {
      const res = await axiosInstance.get("/collections/" + id);
      store.commit(`${flow}/setCollection`, res.data);
      await setup(flow);
      console.log("Collection:", res.data);
      return Promise.resolve(res.data);
    } else {
      return Promise.reject("No collection ID");
    }
  };
  return {
    loadCollection,
    login,
    followLead,
    // updateMilestone,
  };
};
const collectionLib = Collections();
export { collectionLib };
