
import { RefreshFlow } from "@/models/dashboard";
import { Component, Vue, PropSync } from "vue-property-decorator";

@Component
export default class DeleteAccountModal extends Vue {
  @PropSync("obj") current!: RefreshFlow;
  cancel() {
    this.current.open = false;
    this.current.currentComp = null;
  }
  demo() {
    this.current.currentComp = "TwoFactor";
  }
}
