
import { Component, Vue, Prop } from "vue-property-decorator";
import AccountSelectionCard from "./AccountSelectionCard.vue";
import { PAGE, SocketFlow } from "@/models/nav";
import SecurityGuarentee from "@/components/AccountLinking/SecurityGuarentee.vue";
@Component({
  components: {
    AccountSelectionCard,
    SecurityGuarentee,
  },
})
export default class AccountSelection extends Vue {
  @Prop() flow!: SocketFlow;
  goBack() {
    // cancel web socket flow -> Modal for cancel
  }
}
