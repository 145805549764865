
import { Component, Vue, Prop } from "vue-property-decorator";
import TimeoutCard from "@/components/AccountLinking/Timeout/TimeoutCard.vue";
import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    TimeoutCard,
  },
})
export default class Error extends Vue {
  @Prop() flow!: SocketFlow;
}
