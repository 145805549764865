
import { Component, Vue, Watch } from "vue-property-decorator";
import { axiosNoAuth, axiosInstance } from "@/plugins/axios";
import axios, { AxiosError } from "axios";
import { FieldValidationLib } from "@/helpers";
import VueRecaptcha from "vue-recaptcha";
import TermsAndConditionsModal from "@/components/TermsAndConditionsModal.vue";
import AuthModule from "@/store/modules/Auth";

@Component({
  components: {
    VueRecaptcha,
    TermsAndConditionsModal,
  },
})
export default class Register extends Vue {
  valid = false;
  captcha = false;
  loading = false;
  fullName: string | null = null;
  idNumber: string | null = null;
  email: string | null = null;
  tcs = false;
  error: {
    code: null | number;
    message: string;
    alreadyExists: boolean;
    general: boolean;
  } = {
    code: null,
    message: "Unknown",
    alreadyExists: false,
    general: false,
  };

  get getValid() {
    return this.valid == true && this.captcha == true;
  }
  @Watch("$route.query.email", {
    deep: true,
    immediate: true,
  })
  onQueryChanged(val: string, oldval: string) {
    console.log("Query", val);
    if (typeof val === "string") {
      this.email = val;
    } else {
      this.email = null;
    }
  }
  verifyCaptcha(val: any) {
    console.log("Verify captcha", val);
    this.captcha = true;
  }
  errorCaptcha(val: any) {
    console.log("ERror captcha ", val);
    this.captcha = false;
  }
  expiredCaptcha(val: any) {
    console.log("Expired captcha ", val);
    this.captcha = false;
  }
  get getSiteKey() {
    return process.env.VUE_APP_RECAPTCHA;
  }
  get getRules() {
    return FieldValidationLib.rules;
  }
  nameRules = [
    (v: string | undefined) => !!v || "Enter your name",
    (v: string | undefined) => {
      if (!v) return false;
      return (
        (v.length >= 1 && v.length <= 50) ||
        "Requires between 1 and 50 characters"
      );
    },
    (v: string | undefined) => {
      if (!v) return false;
      const regex = /^[A-Za-z0-9 ]+$/;
      return regex.test(v) || "No special characters allowed";
    },
  ];
  idRules = [
    (v: string | undefined) => !!v || "Required",
    (v: string | undefined) => {
      if (!v) return false;
      const pattern = /^[0-9]*$/;
      return pattern.test(v) || "Requires numbers only";
    },
    (v: string | undefined) => {
      if (!v) return false;
      return (v.length >= 1 && v.length <= 13) || "Requires valid 13 digit ID";
    },
  ];
  emailRules = [
    (v: string | undefined) => !!v || "Enter your Email",
    (v: string | undefined) => {
      if (!v) return false;
      // const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/;
      const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return pattern.test(v) || "Valid email required";
    },
  ];

  // mounted() {
  //   this.$nextTick(() => {
  //     (this.$refs.registerForm as any).validate();
  //   });
  // }

  async submitCredentials(): Promise<void> {
    try {
      this.loading = true;
      if (this.email) {
        const url = "/createAccount";
        const res = await axiosInstance.post(url, {
          name: this.fullName,
          email: this.email,
          "id-number": this.idNumber,
        });
        AuthModule.setEnteredEmail(this.email.toLowerCase());
        AuthModule.setOTPResponse(res.data);
        console.log("Sign up response:   ", res.data);
        this.$router.push({
          name: "otp",
          query: {
            from: "Sign Up",
          },
        });
      }

      return Promise.resolve();
    } catch (err) {
      if (axios.isAxiosError(err) == true) {
        const temp = err as AxiosError;
        if (temp.response) {
          if (temp.response.status == 409) {
            this.error.alreadyExists = true;
          }
          this.error.message = (temp.response.data as any).message as string;
          this.error.code = temp.response.status;
        }
        console.error("Error ", temp.toJSON());
      }
    } finally {
      this.loading = false;
    }
  }
}
