
import { Component, Vue, Prop } from "vue-property-decorator";
import BankSelectionCard from "@/components/AccountLinking/BankSelection/BankSelectionCard.vue";

import { SocketFlow } from "@/models/nav";

@Component({
  components: {
    BankSelectionCard,
  },
})
export default class BankSelection extends Vue {
  @Prop() flow!: SocketFlow;
  async goBack() {
    const temp = this.$refs.bankselection;
    if (temp) {
      (temp as BankSelectionCard).goBack();
    }
  }
}
