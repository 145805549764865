
import { Component, Vue, Prop } from "vue-property-decorator";
import NavModule from "@/store/modules/Nav";
import { dataProvidersLib } from "@/helpers/dataProviders";
import DataProvidersModule from "@/store/modules/DataProviders";
import AccountLinking from "@/store/modules/AccountLinking";
import CompleteYourProfile from "@/components/CompleteProfile/CompleteYourProfile.vue";
import LottieAnimation from "lottie-web-vue";
import loadingAnimation from "@/assets/loadingAnimation.json";
import { SocketFlow } from "@/models/nav";
import store from "@/store";

@Component({
  filters: {
    // bankName: (val: string | undefined) => {
    //   if (val == undefined) return "Undefined";
    //   switch(val) {
    //   }
    // }
  },
  components: {
    CompleteYourProfile,
    LottieAnimation,
  },
})
export default class ProcessingStatusCard extends Vue {
  @Prop() flow!: SocketFlow;
  loadingAnimation = loadingAnimation;
  get getCurrentStatus() {
    return store.getters[`${this.flow}/getStatusMessage`];
  }
}
