
import { Component, Vue, Prop } from "vue-property-decorator";
import dayjs from "dayjs";
import Timer from "@/components/Timer.vue";
import {
  Collection,
  // stompClient,
  DetailsAccountSelection,
  SocketChallenge,
  SocketPayload,
  SocketPayloadChallenge,
  StompClient,
} from "truid-common";
import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";
import { DataProvider } from "@/models/dataProviders";

@Component({
  components: {
    Timer,
  },
})
export default class AccountSelectionCard extends Vue {
  @Prop() flow!: SocketFlow;
  selectedAccounts: string | null = null;
  timerInterval: number | null = null;
  timeStarted: number | null = null;
  mockLoad = false;
  // timerLimit: number | null = null;
  timeLimit = 300;
  currentTime = dayjs().valueOf();
  linkAccount() {
    console.log("Link account");
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.Overview);
  }
  get getAccounts() {
    if (this.getChallenge) {
      if ((this.getChallenge.details as DetailsAccountSelection).accounts) {
        return (this.getChallenge.details as DetailsAccountSelection).accounts;
      }
    }
    return [];
  }
  get getSelectedBank(): DataProvider {
    return store.getters[`${this.flow}/getSelectedDataProvider`];
  }
  submitChallenge() {
    console.log("Submit challenge");
    if (this.getChallenge && this.getCollectionID && this.getCollection) {
      const temp: SocketPayloadChallenge = {
        "challenge-id": this.getChallenge.id,
        response: [this.selectedAccounts],
      };
      const payload: SocketPayload = {
        name: "challenge-response",
        "collection-id": this.getCollectionID,
        "wss-token": this.getCollection["wss-token"],
        payload: temp,
      };
      const st: StompClient | null = store.getters[`${this.flow}/getStomp`];
      st?.send(payload);
      store.commit(`${this.flow}/setChallenge`, null);
      store.commit(`${this.flow}/setChallengeID`, null);
      this.mockLoad = true;
      setTimeout(() => {
        this.mockLoad = false;
      }, 4000);

      // NavModule.setCurrentComponent(PAGE.Processing);
    }
  }
  mounted() {
    this.checkTimeOnMounted();
    this.startTimer();
  }
  startTimer() {
    this.timerInterval = setInterval(
      () => (this.currentTime = dayjs().valueOf()),
      1000
    );
  }
  restartTimer() {
    if (this.timerInterval != null) clearInterval(this.timerInterval);
    this.checkTimeOnMounted();
    this.startTimer();
  }
  get getCollectionID(): string | null {
    if (store.getters[`${this.flow}/getCollectionID`])
      return store.getters[`${this.flow}/getCollectionID`];
    else return null;
  }
  get getCollection(): Collection | null {
    if (store.getters[`${this.flow}/getCollection`])
      return store.getters[`${this.flow}/getCollection`];
    else return null;
  }
  get getChallenge(): SocketChallenge | null {
    if (store.getters[`${this.flow}/getChallenge`])
      return store.getters[`${this.flow}/getChallenge`];
    else return null;
  }
  get getChallengeDetails() {
    if (this.getChallenge)
      return this.getChallenge.details as DetailsAccountSelection;
    else return null;
  }
  goBack() {
    // cancel web socket flow -> Modal for cancel
  }
  checkTimeOnMounted() {
    // if (this.getChallenge) {
    //   this.timerLimit = dayjs(this.getChallenge.timestamp).toDate().valueOf();
    // }
    if (this.getChallenge) {
      this.timeStarted = dayjs(this.getChallenge.timestamp).toDate().valueOf();
      console.log(
        "Time stamp ",
        this.getChallenge.timestamp,
        dayjs(this.getChallenge.timestamp)
      );
      this.timeLimit = this.getChallenge.timeout / 1000;
    }
  }
  get formattedTimeLeft() {
    const timeLeft = this.timeLeft;
    const minutes = Math.floor(this.timeLeft / 60);
    let seconds = (timeLeft % 60).toString();

    if (timeLeft % 60 < 10) {
      seconds = `0${timeLeft % 60}`;
    }

    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds}`;
  }
  get timeLeft() {
    // if (this.timerLimit != null) {
    //   const diff = dayjs(this.timerLimit).diff(this.currentTime, "seconds");
    //   if (diff <= 0) {
    //     return 0;
    //   }
    //   return diff;
    // }
    // return 0;
    if (this.timeStarted != null) {
      const temp =
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000);
      return temp >= 0 ? temp : 0;
    }
    return 0;
  }
}
