import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { CURRENT_USER, LoginResponse, TokenInfo } from "@/models/user";
import Dashboard from "./Dashboard";

export interface login {
  email: string | null;
  password: string | null;
}
export interface OTP_RESPONSE {
  "consumer-id": string;
  "otp-id"?: string;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Auth",
  store,
})
class Auth extends VuexModule {
  label = "truid-consumer-portal-ui";
  token: null | string = null;
  otpResponse: OTP_RESPONSE | null = null;
  loginResponse: null | string = null;
  user: null | CURRENT_USER = null;
  valid = false;
  enteredEmail: string | null = null;
  expiring = {
    open: false,
    interval: 0,
  };
  get getExpiringJWT() {
    return this.expiring;
  }
  @Mutation
  setExpiringJWT(val: { open: boolean; interval: number }) {
    this.expiring = val;
  }
  get getToken() {
    return this.token;
  }
  @Mutation
  setToken(val: string | null) {
    this.token = val;
  }
  get getTokenInfo() {
    const tok = this.getToken;
    if (!tok) return null;
    const consumer: TokenInfo = JSON.parse(atob(tok.split(".")[1]));
    return consumer;
  }
  get getOTPResponse() {
    return this.otpResponse;
  }
  @Mutation
  setOTPResponse(val: OTP_RESPONSE | null) {
    this.otpResponse = val;
  }
  get getEnteredEmail(): string | null {
    return this.enteredEmail;
  }
  @Mutation
  setEnteredEmail(val: string | null) {
    this.enteredEmail = val;
  }
  get getUser(): CURRENT_USER | null {
    return this.user;
  }
  @Mutation
  setUser(val: CURRENT_USER | null) {
    this.user = val;
  }

  // get getLoginResponse() {
  //   return this.loginResponse;
  // }
  // @Mutation
  // setLoginResponse(val: string | null) {
  //   this.loginResponse = val;
  // }
  @Action
  getTokenFromStorage() {
    const temp = localStorage.getItem(this.label);
    if (temp) {
      const consumer = JSON.parse(atob(temp.split(".")[1]));
      this.setOTPResponse({
        "consumer-id": consumer.sub,
      });
      this.setToken(temp);
    } else {
      this.setToken(null);
    }
  }
  @Action
  setLocalStorage(val: string | null) {
    if (val == null) localStorage.removeItem(this.label);
    else localStorage.setItem(this.label, val);
  }
  @Action
  async logout() {
    localStorage.removeItem(this.label);
    this.setToken(null);
    this.setOTPResponse(null);
    clearInterval(this.expiring.interval);
    this.setEnteredEmail(null);
    this.setUser(null);
    // await Dashboard.clear();
    // if (store.hasModule("AccountLinking")) {
    //   await store.dispatch("AccountLinking/clear", "AccountLinking");
    // }
  }
  get isLoggedIn() {
    // return true;
    return this.getToken != null;

    // return this.valid;
  }
}
export default getModule(Auth);
