
import { Component, Vue, Watch } from "vue-property-decorator";
import UserModule from "@/store/modules/User";

@Component
export default class UpdatePersonalDetailsModal extends Vue {
  get open() {
    return UserModule.isPersonalDetailsOpen;
  }
  set open(val: boolean) {
    UserModule.setPersonalDetailsOpen(val);
  }
}
