import { axiosInstance } from "@/plugins/axios";
import Auth from "@/store/modules/Auth";
import DashboardModule from "@/store/modules/Dashboard";
import { populateDashboardData } from "@/models/dashboard";
import store from "@/store";
import { SocketFlow } from "@/models/nav";
import Slider from "@/store/modules/Slider";
// import { PAGE, TypeOfUser } from "@/models/user";
// import { MILESTONE } from "@/models/socket";

const Dashboard = () => {
  const refreshData = async () => {
    if (Auth.getOTPResponse) {
      const res = await axiosInstance.get(
        "/consumers/" +
          Auth.getOTPResponse["consumer-id"] +
          "/dashboards/refresh"
      );
    }
  };
  const getData = async () => {
    if (Auth.getOTPResponse) {
      const res = await axiosInstance
        .get("/consumers/" + Auth.getOTPResponse["consumer-id"] + "/dashboards")
        .catch(async (err) => {
          // if (axios.isAxiosError(err)) {
          //   console.log("Errrrr: ", err);
          //   if (err.response) {
          //     if (err.response.status == 404) {
          //       return await populateData();
          //     }
          //   }
          // }
          return Promise.reject(err);
        });

      if (res == null) {
        DashboardModule.setDashboardData(res);
      } else {
        const temp: populateDashboardData = {
          ...res.data,
          "json-data": JSON.parse(res.data["json-data"]),
        };
        DashboardModule.setDashboardData(temp);
        console.log("Calling leads in data ", Slider.getCurrentSpread);
        await new Promise((resolve) => setTimeout(resolve, 0));
        console.log("Calling leads in data after ", Slider.getCurrentSpread);
        await DashboardLib.getAvailLeads(1);
      }
    }

    return Promise.resolve();
  };
  const populateData = async (flow: SocketFlow) => {
    if (Auth.getOTPResponse) {
      const res = await axiosInstance.post(
        "/consumers/" + Auth.getOTPResponse["consumer-id"] + "/dashboards",
        {
          "lead-id": "customLeadId",
        }
      );
      console.log("Populate headers: ", res.headers);
      if (res.headers.location) {
        console.warn("Have collection ID");
        const id = res.headers.location.split("/").pop();
        // if (id) AccountLinking.setCollectionID(id);
        if (id) {
          store.commit(`${flow}/setCollectionID`, id);
        }
      }
    }
    return Promise.resolve(null);
  };
  // const createLeadCollection = async (val: lead) => {
  //   if (Auth.getOTPResponse) {
  //     const id = Auth.getOTPResponse["consumer-id"];
  //     const res = await axiosInstance.get("/consumers/" + id + "/collections", {
  //       params: {
  //         "lead-id": val.id,
  //         "brand-id": val["brand-id"],
  //         services: ["eeh03fzauckvj8u982dbeq1d8"],
  //       },
  //     });
  //     console.log("Created lead collection: ", res);
  //     // AccountLinking.setLeads(res.data);
  //   }
  //   return Promise.resolve(null);
  // };
  const getAvailLeads = async (instalments: number) => {
    try {
      if (Auth.getOTPResponse) {
        DashboardModule.setLoadingLeads(true);
        const res = await axiosInstance.get("/leads", {
          params: {
            consumerId: Auth.getOTPResponse["consumer-id"],
            instalments,
            affordability: Slider.getCurrentSpread,
            pageNr: 0,
            pageSize: 20,
          },
        });
        console.log("Avail leads: ", res.data);
        DashboardModule.setLeads(res.data);
      }
      return Promise.resolve(null);
    } finally {
      DashboardModule.setLoadingLeads(false);
    }
  };

  return {
    getData,
    populateData,
    getAvailLeads,
    // createLeadCollection,
    refreshData,
  };
};
const DashboardLib = Dashboard();
export { DashboardLib };
