
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { PAGE, SocketFlow } from "@/models/nav";
import store from "@/store";
import { DashboardLib } from "@/helpers";
import Dashboard from "@/store/modules/Dashboard";

@Component
export default class GetStartedCard extends Vue {
  @Prop() flow!: SocketFlow;
  async goBack() {
    if (this.flow == "Dashboard/AccountLinking") {
      console.log("Clearing dashboard");
      const temp = Dashboard.getRefreshFlow;
      Dashboard.setRefreshFlow({
        ...temp,
        open: false,
      });
      await store.dispatch(`${this.flow}/clear`, this.flow);
    }
  }
  async init() {
    if (
      store.getters[`${this.flow}/getCollectionID`] == null ||
      store.getters[`${this.flow}/getLockedAtLogin`]
    ) {
      if (store.getters[`${this.flow}/getLockedAtLogin`]) {
        store.commit(`${this.flow}/setLockedAtLogin`, false);
      }
      await DashboardLib.populateData(this.flow);
    }
  }
  async activated() {
    await this.init();
  }
  async mounted() {
    await this.init();
  }
  getStarted() {
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.Consent);
  }
}
