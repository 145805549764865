import { axiosInternal } from "@/plugins/axios";

const Config = () => {
  const checkMaintenance = async (): Promise<boolean> => {
    const res = await axiosInternal.get("config.json");
    console.log("Check maintenance ", res.data);
    if (res.data.maintenance && res.data.maintenance == true) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  };
  return {
    checkMaintenance,
  };
};
const ConfigLib = Config();
export { ConfigLib };
