
import { Component, Vue, Prop } from "vue-property-decorator";

import Timer from "@/components/Timer.vue";
import { DetailsOTP, SocketChallenge } from "truid-common";
import store from "@/store";
import { SocketFlow } from "@/models/nav";

@Component({
  filters: {
    bankName: (val: string | undefined) => {
      if (val == undefined) return "Undefined";
      if (val.indexOf("(") != -1) {
        return val.split("(")[0];
      } else return val;
    },
  },
  components: {
    Timer,
  },
})
export default class MobileAuthCard extends Vue {
  @Prop() flow!: SocketFlow;
  goBack() {
    // cancel web socket flow -> Modal for cancel
  }
  requestNewOtp() {
    console.log("Request new otp here");
  }
  get getChallenge(): SocketChallenge {
    return store.getters[`${this.flow}/getChallenge`];
  }
  get getChallengeDetails() {
    if (this.getChallenge) return this.getChallenge.details as DetailsOTP;
    else return null;
  }
  get getSelectedBank() {
    // return DataProvidersModule.getSelectedDataProvider;
    return store.getters[`${this.flow}/getSelectedDataProvider`];
  }
}
