
import { Component, Vue, Prop } from "vue-property-decorator";
import { DataProvidersLib } from "@/helpers";
import { DataProvider } from "@/models/dataProviders";
import { PAGE, SocketFlow } from "@/models/nav";

import store from "@/store";

@Component({
  filters: {
    noNew: (val: string) => {
      if (val && val.toLowerCase().includes("new")) {
        return val.replace("(New)", "");
      } else {
        return val;
      }
    },
  },
})
export default class BankSelectionCard extends Vue {
  @Prop() flow!: SocketFlow;
  loading = false;
  next() {
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.BankLogin);
  }
  async goBack() {
    await store.dispatch(`${this.flow}/clear`, this.flow);
    //disconnect socket
    store.commit(`${this.flow}/setCurrentComponent`, PAGE.GetStarted);
  }
  get collectionId(): string {
    return store.getters[`${this.flow}/getCollectionID`];
  }
  get selectedDataProvider(): DataProvider | null {
    return store.getters[`${this.flow}/getSelectedDataProvider`];
  }
  set selectedDataProvider(val: DataProvider | null) {
    store.commit(`${this.flow}/setSelectedDataProvider`, val);
  }
  get getDataProviders(): DataProvider[] {
    return store.getters[`${this.flow}/getDataProviders`];
  }
  async mounted() {
    try {
      this.loading = true;
      await DataProvidersLib.load(this.flow);
    } catch (err) {
      console.error("Cannot load banks: ", err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
