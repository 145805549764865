var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"rounded":"lg","elevation":"0"}},[_c('v-card-title',{staticClass:"text-left"},[_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Borrow up to")]),_c('div',{staticClass:"text-h3"},[_vm._v("R"+_vm._s(_vm.getCurrentSpread))])]),_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"text-h6"},[_vm._v("Loan period")]),_c('div',[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.getValue))]),_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.value > 60 ? "Months" : "Days"))]),_vm._v(" | "),_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.getInstalments)+" Instalment")])])])]),_c('v-card-text',{staticClass:"justify-center flex-column d-flex"},[_c('div',{staticClass:"text-left",staticStyle:{"width":"100%","position":"relative","height":"0","top":"-20px"}},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.value),expression:"value",modifiers:{"number":true}}],ref:"offerSlider",staticClass:"sliderInvisible",staticStyle:{"width":"calc(100% - 5px)"},style:({
          zIndex: 100,
        }),attrs:{"type":"range","min":0,"step":_vm.value >= 60 ? 30 : 1,"max":30 * 7},domProps:{"value":(_vm.value)},on:{"input":_vm.refocusSlider,"__r":function($event){_vm.value=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"text-center justify-center align-center d-flex",staticStyle:{"width":"99%","padding":"0 10px"}},_vm._l((6),function(i){return _c('v-slider',{key:i,staticClass:"customSliders align-center",class:{
          'hidden-thumb':
            i == 1
              ? _vm.value >= 60
              : _vm.value > i * 30 + (i == 6 ? 30 : 29) || _vm.value < i * 30,
        },style:({
          zIndex: 99,
          width:
            i > 1
              ? `${(100 - 6 - (100 / 6) * 2) / (6 - 1)}%`
              : `${((100 - 6) / 6) * 2}%`,
        }),attrs:{"height":"30","thumb-color":"#000","thumb-size":"42","track-color":"#00C18E","track-fill-color":"#C4D82E","readonly":"","value":_vm.value,"min":i == 1 ? 0 : i * 30 + 1,"max":i == 1 ? 60 : (i + 1) * 30}})}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }